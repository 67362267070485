import React, { useContext, useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import context from "../context/context";

const NotificationBadge = () => {
  const { totalCount } = useContext(context);
  const [isShow, setIsShow] = useState(false);

  const tempTotalCount = parseInt(
    localStorage.getItem("totalCountInLoadData"),
    10
  );
  useEffect(() => {
    if (totalCount > 0 || tempTotalCount > 0) {
      setIsShow(true);
    }
  }, [totalCount]);

  //console.log('totallllll',totalCount)

  return (
    <div className="position-relative">
      <div className="position-absolute bottom-0 start-0 translate-middle rounded-pill p-1 ms-3">
        {isShow && (
          <Badge className="badge" bg="danger">
            {totalCount > 0 ? totalCount : tempTotalCount} left
          </Badge>
        )}
      </div>
    </div>
  );
};

export default NotificationBadge;
