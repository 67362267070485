import React from "react";
import ReactDOM from "react-dom/client";
//import axios from "axios";

import App from "./App";
import { ContextProvider } from "./context/context";
import { ContextProvider2 } from "./context/shipInfoContext";
import { SocketProvider } from "./context/socketContext";
import ThemeProvider from "react-bootstrap/ThemeProvider";
//import Header from "./components/header";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ThemeProvider
    breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
    minBreakpoint="xxs"
  >
    {/* <Header /> */}
    <SocketProvider>
    <ContextProvider>
      <ContextProvider2>
        <App />
      </ContextProvider2>
    </ContextProvider>
    </SocketProvider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
