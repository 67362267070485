module.exports = function convertRatio(totalQty, ratios) {
  const total = ratios.reduce((sum, ratio) => sum + ratio, 0);
  if (totalQty % total !== 0) return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const factor = totalQty / total;

  const result = ratios.map((x) => x * factor);
  // console.log("result" + result);
  const RplaceResult = result.map((value) => {
    return value === 0 ? "" : value;
  });

  // console.log("RplaceResult" + RplaceResult);

  return RplaceResult;
};
