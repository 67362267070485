import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/header";
import { PieChart } from "react-minimal-pie-chart";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
// import InputBox from "../../components/InputBox";
import InputBox from "./InputBox";
import axios from "axios";
import context from "../../context/context";
import shipContext from "../../context/shipInfoContext";
import toast from "react-hot-toast";
// import { v4 as uuidv4 } from "uuid";

// import Input from "react-select/dist/declarations/src/components/Input";
// import "./Shipment.css"

const ShipInfo = () => {
  const { formData, setFormData } = useContext(context);
  const { checkUpdateButton, setCheckUpdateButton } = useContext(shipContext); // to check if existing data is fetched or not
  const [isDataLoading, setIsDataLoading] = useState(false); // for loader in submit button
  const [shipmentSuggestion, setShipmentSuggestion] = useState([]); // for showing suggestion in factory shipment
  const [sentShipmentSuggestion, setSentShipmentSuggestion] = useState([]); // for showing sentSuggestion in factory shipment
  const [activeSuggestion, setActiveSuggestion] = useState(0);

  const [isDisabled, setIsDisabled] = useState(false);

  const [disableAfterSelect, setDisableAfterSelect] = useState(false);

  const blankFormData = {
    shipNo: "",
    shipGroup: "",
    shipmentGroup: "",

    awbbl1: "",
    awbbl2: "",
    awbbl3: "",
    awbbl4: "",
    awbbl5: "",
    //  awbbl6: "",
    awbbldate: null,
    docStatus: "",
    etd: null,
    etaLager: null,
    lastEtaLager: null,
    deliveryNo: "",
    shipVia: "",
    description: "",
    etaPort: null,
    currentTime: null,
    warehouse: "",
    // shipmentCreationDate: "",
    countryOfOrigin: "",
    // countryName: "",
    cartons1: "",
    cartons2: "",
    cartons3: "",
    cartons4: "",
    cartons5: "",
    // cartons6: "",
    weightInKg: "",
    containerNo1: "",
    containerSize1: "",
    volume1: "",
    containerNo2: "",
    containerSize2: "",
    volume2: "",
    containerNo3: "",
    containerSize3: "",
    volume3: "",
    containerNo4: "",
    containerSize4: "",
    volume4: "",
    containerNo5: "",
    containerSize5: "",
    volume5: "",

    mv: "",
    portOfDispatch: "",
    resolutionPlan: "",
    // dispatchName: "",
    pieces: "",
    // lager: "",
    shipmentCrDate: null,
    factory: "",
    consignee: "",
    actualCarrier: "",
    destination: "",
    fltVoyageTruck: "",
    actualEtd: null,
    actualEta: null,
  };

  useEffect(() => {
    if (sentShipmentSuggestion) {
      setIsDisabled(sentShipmentSuggestion.includes(formData.shipNo));
    } else {
      setIsDisabled(false);
    }
  }, [formData, sentShipmentSuggestion]);

  // console.log("form data", formData);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    // console.log('name', name)
    // console.log('value', value)
    let realValue = value;
    if (name === "shipGroup" && value.length > 8) {
      realValue = value.slice(0, 8);
    }
    if (name === "shipNo" && value.length > 20) {
      realValue = value.slice(0, 20);
    }
    const cartonFields = [
      "cartons1",
      "cartons2",
      "cartons3",
      "cartons4",
      "cartons5",
    ];
    cartonFields.forEach((cartonField) => {
      // if (name === cartonField && value.length > 5) {
      //   realValue = value.slice(0, 5);
      // }
      if (name === cartonField) {
        if (value.length > 10) {
          realValue = value.slice(0, 10); // Truncate the value to 10 characters
        } else {
          realValue = value.replace(/[^\d.]/g, "");
        }
        return;
      }
    });
    const volumeFields = [
      "volume1",
      "volume2",
      "volume3",
      "volume4",
      "volume5",
      // "volume6",
    ];
    volumeFields.forEach((volumeField) => {
      if (name === volumeField) {
        if (value.length > 10) {
          realValue = value.slice(0, 10); // Truncate the value to 10 characters
        }
        //  else {
        //   // Replace non-numeric characters with an empty string
        //   realValue = value.replace(/[^\d.]/g, "");
        // }
        return;
      }
    });
    const awbblFields = [
      "awbbl1",
      "awbbl2",
      "awbbl3",
      "awbbl4",
      "awbbl5",
      // "awbbl6",
    ];
    awbblFields.forEach((awbblField) => {
      if (name === awbblField && value.length > 15) {
        realValue = value.slice(0, 15);
      }
    });
    const containerFields = [
      "containerNo1",
      "containerNo2",
      "containerNo3",
      "containerNo4",
      "containerNo5",
      // "containerNo6",
    ];
    containerFields.forEach((containerField) => {
      if (name === containerField && value.length > 15) {
        realValue = value.slice(0, 15);
      }
    });
    // containerSize1
    const containerSizeFields = [
      "containerSize1",
      "containerSize2",
      "containerSize3",
      "containerSize4",
      "containerSize5",
      // "containerSize6",
    ];
    containerSizeFields.forEach((containerSizeField) => {
      if (name === containerSizeField && value.length > 10) {
        realValue = value.slice(0, 10);
      }
    });
    if (name === "docStatus" && value.length > 40) {
      realValue = value.slice(0, 40);
    }
    if (name === "shipmentGroup" && value.length > 3) {
      realValue = value.slice(0, 3);
    }
    if (name === "shipVia" && value.length > 3) {
      realValue = value.slice(0, 3);
    }
    if (name === "description" && value.length > 25) {
      realValue = value.slice(0, 25);
    }
    if (name === "warehouse" && value.length > 2) {
      realValue = value.slice(0, 2);
    }
    if (name === "countryOfOrigin" && value.length > 3) {
      realValue = value.slice(0, 3);
    }
    if (name === "portOfDispatch" && value.length > 3) {
      realValue = value.slice(0, 3);
    }
    if (name === "resolutionPlan" && value.length > 10) {
      realValue = value.slice(0, 10);
    }
    if (name === "pieces") {
      if (value.length > 8) {
        realValue = value.slice(0, 8); // Truncate the value to 10 characters
      } else {
        // Replace non-numeric characters with an empty string
        realValue = value.replace(/[^\d.]/g, "");
      }
    }
    if (name === "mv" && value.length > 30) {
      realValue = value.slice(0, 30);
    }
    if (name === "weightInKg") {
      // Remove any non-numeric characters except for the decimal point
      realValue = value.replace(/[^\d.]/g, "");
      // Apply regex to limit the format
      const weightRegex = /^\d{0,8}(?:\.\d{0,2})?$/;
      if (!weightRegex.test(realValue)) {
        // If the value doesn't match the regex, don't update the state
        return;
      }
    }
    setFormData({
      ...formData,
      [name]: realValue.toUpperCase(),
    });

    if (name === "shipNo" && realValue) {
      try {
        const response = await axios.get(
          `/shipInfo/getUnsent?shipNo=${realValue}`
        );
        // console.log("response for shipInfo", response.data.shipData);
        const suggestionData = response.data.shipData.map(
          (suggestion) => suggestion.shipNo
        );
        const sentSuggestionData = response.data.shipData
          .filter((suggestion) => suggestion.sent === 1)
          .map((suggestion) => suggestion.shipNo);
        // console.log('sent suggestion data', sentSuggestionData)
        // console.log('changed into array', suggestionData)
        setShipmentSuggestion(suggestionData);
        setSentShipmentSuggestion(sentSuggestionData);
        setActiveSuggestion(0);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    } else {
      setShipmentSuggestion([]);
    }
  };

  // console.log("suggestion list", shipmentSuggestion);
  // console.log("sent suggestion list", sentShipmentSuggestion);

  function convertLocalToUTCDate(date) {
    if (!date) {
      return date;
    }
    date = new Date(date);
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return date;
  }

  const handleDateChange = (date, columnName) => {
    if (!date) return;
    // const preparedData = {
    //   ...formData,
    //   etd: formData.etd ? formData.etd.toISOString().substring(0, 10) : "",
    //   etaPort: formData.etaPort ? formData.etaPort.toISOString().substring(0,10) : "",
    //   currentTime: formData.currentTime
    //     ? formData.currentTime.toISOString().substring(0,10)
    //     : "",
    //   shipmentCrDate: formData.shipmentCrDate
    //     ? formData.shipmentCrDate.toISOString().substring(0,10)
    //     : "",
    // };
    const dateObject = convertLocalToUTCDate(date);
    // Apply your desired date formatting logic here
    // realValue = dateObject.toISOString().substring(0, 10);
    let realDate;
    if (
      columnName === "etd" ||
      columnName === "etaPort" ||
      // columnName === "currentTime" ||
      columnName === "shipmentCrDate" ||
      columnName === "etaLager" ||
      columnName === "lastEtaLager" ||
      columnName === "awbbldate" ||
      columnName === "actualEtd" ||
      columnName === "actualEta" ||
      columnName === "cargoDate"
    ) {
      realDate = dateObject.toISOString().split("T")[0];
      // console.log("selected date", date);
      // console.log("formatted date", realDate);
    }
    setFormData({
      ...formData,
      [columnName]: realDate,
    });
  };

  const handleTimeChange = (time) => {
    // const dateObject = new Date(date);
    // console.log("time what ", time);
    let realTime = time;
    // if(columnName === "currentTime" ){
    //   realDate = dateObject.toISOString().substring(0, 10);
    // }
    setFormData({
      ...formData,
      currentTime: time,
    });
  };

  // console.log("form data", formData);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.shipNo === "") {
      toast.remove();
      return toast.error("Please fill the required input.");
    }
    if (formData.shipNo.length < 6) {
      toast.remove();
      return toast.error(
        "factory shipment should be greater than or Equal to 6 digits."
      );
    }
    // const preparedData = {
    //   ...formData,
    //   etd: formData.etd ? formData.etd.toISOString().substring(0, 10) : "",
    //   etaPort: formData.etaPort ? formData.etaPort.toISOString().substring(0,10) : "",
    //   currentTime: formData.currentTime
    //     ? formData.currentTime.toISOString().substring(0,10)
    //     : "",
    //   shipmentCrDate: formData.shipmentCrDate
    //     ? formData.shipmentCrDate.toISOString().substring(0,10)
    //     : "",
    // };

    // console.log("Prepared data:", preparedData);
    // console.log('submit form data')

    setIsDataLoading(true);
    try {
      // if(checkUpdateButton){

      // }
      const checkStatus = await axios.get(
        `/shipInfo/check?shipNo=${formData.shipNo}`
      );
      // console.log("data from check api", checkStatus.data);
      if (checkStatus.data.new === false && !checkUpdateButton) {
        toast.remove();
        setIsDataLoading(false);
        return toast.error("Factory shipment already exists.");
      }
      // console.log("check status from new api ", checkStatus);

      // let columnName;
      // if(columnName === "etd" || columnName === "etaPort" || columnName === "currentTime" || columnName === "shipmentCrDate"){
      //   realDate = dateObject.toISOString().substring(0, 10);
      // }
      // setFormData({
      //   ...formData,
      //   [columnName]: realDate,
      // });
      // const dateObject = new Date(date);
      const updatedData = {
        // for modifying data after fetching
        ...formData,
        etd: formData.etd
          ? new Date(formData.etd).toISOString().substring(0, 10)
          : "",
        etaPort: formData.etaPort
          ? new Date(formData.etaPort).toISOString().substring(0, 10)
          : "",
        shipmentCrDate: formData.shipmentCrDate
          ? new Date(formData.shipmentCrDate).toISOString().substring(0, 10)
          : "",
      };

      // const newId = uuidv4();
      // console.log('new Id lessgo', newId)
      const response = await axios.post("/shipInfo/create", {
        data: checkUpdateButton ? updatedData : formData,
        // data: formData
      });
      if (checkUpdateButton) {
        toast.remove();
        toast.success(`${formData.shipNo} successfully updated`);
        setCheckUpdateButton(false);
      } else {
        toast.remove();
        toast.success(`${formData.shipNo} successfully created`);
      }

      setFormData(blankFormData);
      // console.log("Response data:", response.data);
      setIsDataLoading(false);
      setDisableAfterSelect(false);
      // Handle response data
    } catch (error) {
      setIsDataLoading(false);
      setDisableAfterSelect(false);
      toast.remove();
      toast.error("something went wrong");
      console.error("Error:", error);
      // Handle error
    }
  };

  const handleReset = () => {
    setCheckUpdateButton(() => false);
    setFormData(blankFormData);
    setDisableAfterSelect(false);
  };

  // console.log("checking check update", checkUpdateButton);
  // console.log("is disable true or false", disableAfterSelect);

  return (
    <>
      <div className="fixed-top">
        <Header />
        <h3 className="text-center p-1" style={{ background: "#f5f8fa" }}>
          Shipment Information
        </h3>
      </div>
      <div style={{ marginTop: "150px" }}>
        <Container>
          <Form className="pt-50 pb-5">
            <Row className="">
              <InputBox
                firstRow={"firstRow"}
                onChange={handleInputChange}
                onChangeDate={handleDateChange}
                suggestionList={shipmentSuggestion} // for factory ship
                setSuggestionList={setShipmentSuggestion} // for factory ship
                sentShipmentSuggestion={sentShipmentSuggestion} // for factory ship
                activeSuggestion={activeSuggestion} // for factory ship
                setActiveSuggestion={setActiveSuggestion} // for factory ship
                isDisabled={isDisabled}
                setDisableAfterSelect={setDisableAfterSelect}
                disableAfterSelect={disableAfterSelect}
              />
            </Row>

            <Row className="mt-2">
              <InputBox
                secondRow={"secondRow"}
                onChange={handleInputChange}
                onChangeDate={handleDateChange}
                isDisabled={isDisabled}
                setDisableAfterSelect={setDisableAfterSelect}
                disableAfterSelect={disableAfterSelect}
              />
            </Row>
            <hr />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  borderRadius: ".3em",
                  padding: "2% 1%",
                  background: "white",
                  border: "1px solid silver",
                  width: "49%",
                }}
              >
                <Row>
                  {/* <InputBox
                labelName={"Factory Shipment"}
                // inputName={"shipGroup"}
                maxLength={20}
                inputName={"shipNo"}
                placeholder={"Factory Shipment(6-20)"}
                value={formData["shipNo"]}
                // value={formData["shipGroup"]}
                onChange={handleInputChange}
                suggestionList={shipmentSuggestion}
                setSuggestionList={setShipmentSuggestion}
                sentShipmentSuggestion={sentShipmentSuggestion}
                activeSuggestion={activeSuggestion}
                setActiveSuggestion={setActiveSuggestion}
                isDisabled={isDisabled}
                setDisableAfterSelect={setDisableAfterSelect}
                disableAfterSelect={disableAfterSelect}
              /> */}

                  {/* <InputBox
                labelName={" Lerros Shipment "}
                inputName={"shipGroup"}
                maxLength={8}
                placeholder={"Lerros Shipment"}
                value={formData["shipGroup"]}
                onChange={handleInputChange}
                disabled={false}
                isDisabled={isDisabled}
                setDisableAfterSelect={setDisableAfterSelect}
                disableAfterSelect={disableAfterSelect}
              /> */}
                  <InputBox
                    labelName={"Shipment Group "}
                    inputName={"shipmentGroup"}
                    maxLength={3}
                    placeholder={" Shipment Group"}
                    value={formData["shipmentGroup"]}
                    onChange={handleInputChange}
                    isDisabled={isDisabled}
                    setDisableAfterSelect={setDisableAfterSelect}
                    disableAfterSelect={disableAfterSelect}
                  />
                  {/* <InputBox
                labelName={"Ship Via"}
                inputName={"shipVia"}
                placeholder={"Ship Via"}
                value={formData["shipVia"]}
                onChange={handleInputChange}
                isDisabled={isDisabled}
                setDisableAfterSelect={setDisableAfterSelect}
                disableAfterSelect={disableAfterSelect}
              /> */}
                </Row>
                <Row>
                  {/* <InputBox
                labelName={"AWB/B/L1"}
                inputName={"awbbl1"}
                placeholder={"awb_b_l1"}
                value={formData["awbbl1"]}
                onChange={handleInputChange}
                isDisabled={isDisabled}
                setDisableAfterSelect={setDisableAfterSelect}
                disableAfterSelect={disableAfterSelect}
              /> */}
                  <InputBox
                    labelName={"AWB/B/L Date"}
                    inputName={"awbbldate"}
                    placeholder={"awb_b_l Date"}
                    value={formData["awbbldate"]}
                    onChange={(date) => handleDateChange(date, "awbbldate")}
                    isDisabled={isDisabled}
                    setDisableAfterSelect={setDisableAfterSelect}
                    disableAfterSelect={disableAfterSelect}
                  />
                  <InputBox
                    labelName={"Description"}
                    inputName={"description"}
                    placeholder={"Description"}
                    value={formData["description"]}
                    onChange={handleInputChange}
                    isDisabled={isDisabled}
                    setDisableAfterSelect={setDisableAfterSelect}
                    disableAfterSelect={disableAfterSelect}
                  />
                  <InputBox
                    labelName={"Doc Status"}
                    inputName={"docStatus"}
                    placeholder={"doc status"}
                    value={formData["docStatus"]}
                    onChange={handleInputChange}
                    isDisabled={isDisabled}
                    setDisableAfterSelect={setDisableAfterSelect}
                    disableAfterSelect={disableAfterSelect}
                  />
                </Row>
                {/* <hr /> */}
                <Row>
                  {/* <InputBox
                labelName={"E.T.D."}
                inputName={"etd"}
                placeholder={"ETD"}
                value={formData["etd"]}
                onChange={(date) => handleDateChange(date, "etd")}
                isDisabled={isDisabled}
                setDisableAfterSelect={setDisableAfterSelect}
                disableAfterSelect={disableAfterSelect}
              /> */}
                  {/* <InputBox
                  labelName={"E.T.A. Lager"}
                  inputName={"etaLager"}
                  placeholder={"select a date"}
                  value={formData["etaLager"]}
                  onChange={(date) => handleDateChange(date, "etaLager")}
                  isDisabled={isDisabled}
                  setDisableAfterSelect={setDisableAfterSelect}
                  disableAfterSelect={disableAfterSelect}
                /> */}
                  <InputBox
                    labelName={"Last E.T.A. Lager"}
                    inputName={"lastEtaLager"}
                    placeholder={"select a date"}
                    value={formData["lastEtaLager"]}
                    onChange={(date) => handleDateChange(date, "lastEtaLager")}
                    isDisabled={isDisabled}
                    setDisableAfterSelect={setDisableAfterSelect}
                    disableAfterSelect={disableAfterSelect}
                  />
                  {/* <InputBox
                labelName={"E.T.A. PORT"}
                inputName={"etaPort"}
                placeholder={"select a date"}
                value={formData["etaPort"]}
                onChange={(date) => handleDateChange(date, "etaPort")}
                isDisabled={isDisabled}
                setDisableAfterSelect={setDisableAfterSelect}
                disableAfterSelect={disableAfterSelect}
              /> */}
                  {/* <InputBox
                  labelName={"Current Time"}
                  inputName={"currentTime"}
                  placeholder={"select a date"}
                  value={formData["currentTime"]}
                  onChange={handleTimeChange}
                  isDisabled={isDisabled}
                  setDisableAfterSelect={setDisableAfterSelect}
                  disableAfterSelect={disableAfterSelect}
                /> */}
                </Row>
              </div>
              {/* <hr /> */}
              {/* <Row className="mt-2">
              {/* <InputBox
                labelName={"Delivery Number"}
                inputName={"deliveryNo"}
                placeholder={"Delivery Number"}
                value={formData["deliveryNo"]}
                onChange={handleInputChange}
              /> */}

              {/* <InputBox
                labelName={"Current Time"}
                inputName={"currentTime"}
                placeholder={"Current Time"}
                value={formData["currentTime"]}
                onChange={handleInputChange}
              /> */}
              {/* </Row>  */}

              <div
                style={{
                  borderRadius: ".3em",
                  padding: "2% 1%",
                  background: "white",
                  border: "1px solid silver",
                  width: "49%",
                  height: "43vh",
                }}
              >
                <Row>
                  {/* <InputBox
                labelName={"Country Of Origin"}
                inputName={"countryOfOrigin"}
                placeholder={"Country Of Origin"}
                value={formData["countryOfOrigin"]}
                onChange={handleInputChange}
                isDisabled={isDisabled}
                setDisableAfterSelect={setDisableAfterSelect}
                disableAfterSelect={disableAfterSelect}
              /> */}
                  {/* <InputBox
                labelName={"Country Name"}
                inputName={"countryName"}
                placeholder={"Country Name"}
                value={formData["countryName"]}
                onChange={handleInputChange}
              /> */}
                  {/* <InputBox
                labelName={"Cartons1"}
                inputName={"cartons1"}
                placeholder={"Cartons1"}
                value={formData["cartons1"]}
                onChange={handleInputChange}
                isDisabled={isDisabled}
                setDisableAfterSelect={setDisableAfterSelect}
                disableAfterSelect={disableAfterSelect}
              /> */}
                  <InputBox
                    labelName={"Weight in KG"}
                    inputName={"weightInKg"}
                    placeholder={"Weight in KG"}
                    value={formData["weightInKg"]}
                    onChange={handleInputChange}
                    isDisabled={isDisabled}
                    setDisableAfterSelect={setDisableAfterSelect}
                    disableAfterSelect={disableAfterSelect}
                  />
                  <InputBox
                    labelName={"Container#1"}
                    inputName={"containerNo1"}
                    placeholder={"Container"}
                    value={formData["containerNo1"]}
                    onChange={handleInputChange}
                    isDisabled={isDisabled}
                    setDisableAfterSelect={setDisableAfterSelect}
                    disableAfterSelect={disableAfterSelect}
                  />
                  <InputBox
                    labelName={"Container Size1"}
                    inputName={"containerSize1"}
                    placeholder={"Container Size1"}
                    value={formData["containerSize1"]}
                    onChange={handleInputChange}
                    isDisabled={isDisabled}
                    setDisableAfterSelect={setDisableAfterSelect}
                    disableAfterSelect={disableAfterSelect}
                  />
                  <InputBox
                    labelName={"Volume1"}
                    inputName={"volume1"}
                    placeholder={"Volume1"}
                    value={formData["volume1"]}
                    onChange={handleInputChange}
                    isDisabled={isDisabled}
                    setDisableAfterSelect={setDisableAfterSelect}
                    disableAfterSelect={disableAfterSelect}
                  />
                </Row>
                {/* <hr /> */}
                <Row>
                  <InputBox
                    labelName={"Port of Dispatch"}
                    inputName={"portOfDispatch"}
                    placeholder={"Port of Dispatch"}
                    value={formData["portOfDispatch"]}
                    onChange={handleInputChange}
                    isDisabled={isDisabled}
                    setDisableAfterSelect={setDisableAfterSelect}
                    disableAfterSelect={disableAfterSelect}
                  />
                  {/* <InputBox
                  labelName={"Resolution plan"}
                  inputName={"resolutionPlan"}
                  placeholder={"Resolution plan"}
                  value={formData["resolutionPlan"]}
                  onChange={handleInputChange}
                  isDisabled={isDisabled}
                  setDisableAfterSelect={setDisableAfterSelect}
                  disableAfterSelect={disableAfterSelect}
                /> */}
                  <InputBox
                    labelName={"Pieces"}
                    inputName={"pieces"}
                    placeholder={"Pieces"}
                    value={formData["pieces"]}
                    onChange={handleInputChange}
                    isDisabled={isDisabled}
                    setDisableAfterSelect={setDisableAfterSelect}
                    disableAfterSelect={disableAfterSelect}
                  />
                  {/* <InputBox
                  labelName={"WareHouse"}
                  inputName={"warehouse"}
                  placeholder={"WareHouse"}
                  value={formData["warehouse"]}
                  onChange={handleInputChange}
                  isDisabled={isDisabled}
                  setDisableAfterSelect={setDisableAfterSelect}
                  disableAfterSelect={disableAfterSelect}
                /> */}

                  {/* <InputBox
                labelName={"MV"}
                inputName={"mv"}
                placeholder={"MV"}
                value={formData["mv"]}
                onChange={handleInputChange}
                isDisabled={isDisabled}
                setDisableAfterSelect={setDisableAfterSelect}
                disableAfterSelect={disableAfterSelect}
              /> */}
                  {/* <InputBox
                labelName={"Shipment Cr.Date"}
                inputName={"shipmentCrDate"}
                placeholder={"select a date"}
                value={formData["shipmentCrDate"]}
                onChange={(date) => handleDateChange(date, "shipmentCrDate")}
                isDisabled={isDisabled}
                setDisableAfterSelect={setDisableAfterSelect}
                disableAfterSelect={disableAfterSelect}
              /> */}
                </Row>
              </div>
            </div>

            <Row className="mt-4 ms-1">
              <Button
                onClick={handleSubmit}
                disabled={isDisabled && disableAfterSelect}
              >
                {checkUpdateButton ? (
                  "Update"
                ) : isDataLoading ? (
                  <Spinner size="sm" />
                ) : (
                  "Submit"
                )}
              </Button>
              <Button className="ms-3 btn-danger" onClick={handleReset}>
                Reset
              </Button>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default ShipInfo;
