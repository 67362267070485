import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Container,
  Row,
  Badge,
  Spinner,
} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import toast from "react-hot-toast";

import axios from "axios";
import Header from "../../components/header";
import context from "../../context/context";
import PoFileLoadingStatus from "../../components/PoFileLoadingStatus";


function MyDropzone() {
  //for creating batch or counting files from respective server
  const [poCount, setPoCount] = useState(null);
  const [lotCount, setLotCount] = useState(null);
  const [styCount, setStyCount] = useState(null);
  const [supCount, setSupCount] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const name = useContext(context);
  const widthCheck = name.userData.username === "admin" ? "90%" : "22%";
  // for creating batch for LoadData in navbar
  // const [totalCount, setTotalCount] = useState(0);

  const [selectedFile, setSelectedFile] = useState(null);
  const [option, setOption] = useState("none");
  const [polist, setPoList] = useState([]);
  const [lotList, setLotList] = useState([]);
  const [styList, setStyList] = useState([]);
  const [supList, setSupList] = useState([]);
  const [method, setMethod] = useState("");
  const { userData, totalCount, setTotalCount } = useContext(context);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isSubmitOrDelete, setIsSubmitOrDelete] = useState(false); // for po submission or deletion checker
  const [isSubmitOrDeleteForOthers, setIsSubmitOrDeleteForOthers] =
    useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setSelectedFile(file);
      setMethod("drag");
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "application/json": [".json"],
    },
    onDrop,
  });

  useLayoutEffect(() => {
    //console.log('first useEffect');
    axios
      .get(process.env.REACT_APP_API_LINK + "/api/v1/admin/getPoFiles")
      .then((res) => {
        const length = res.data.list.length;

        setPoCount(length);

        localStorage.setItem("prevPo", length);

        setPoList(
          res.data.list.map((x) => {
            const label = x.hasStartedEntry ? x.name + "(Packing started)" : (x.highlight ? x.name + " (Exists)" : x.name);
            return { value: x.name, label };
          })
        );
        // setTotalCount((prev)=> prev+ length);
        setTotalCount(length + lotCount + styCount + supCount);
      })
      .catch((error) => {
        console.error("Error in Po files", error);
      });
  }, [isSubmitOrDelete]);

  useLayoutEffect(() => {
    localStorage.setItem("totalCountInLoadData", totalCount);
  }, [totalCount]);

  useLayoutEffect(() => {
    // console.log('second useEffect');
    // console.log('userData admin check ', userData.admin)
    // console.log('userData', userData)
    if (userData && userData.admin) {
      // return false
      axios
        .get(process.env.REACT_APP_API_LINK + "/api/v1/admin/getLotFiles")
        .then((res) => {
          const length = res.data.list.length;

          //const prevLotValue = parseInt(localStorage.getItem('prevLot'), 10);
          setLotCount(length);

          localStorage.setItem("prevLot", length);

          setLotList(
            res.data.list.map((x) => {
              return { value: x, label: x };
            })
          );
          // setTotalCount((pre) => pre + length);
          // setTotalCount(length + poCount+ styCount+ supCount)
        })
        .catch((error) => {
          console.error("Error occurs with Lot files", error);
        });

      axios
        .get(process.env.REACT_APP_API_LINK + "/api/v1/admin/getSupFiles")
        .then((res) => {
          const length = res.data.list.length;

          //const prevSupValue = parseInt(localStorage.getItem('prevSup'), 10);

          setSupCount(length);

          localStorage.setItem("prevSup", length);

          setSupList(
            res.data.list.map((x) => {
              return { value: x, label: x };
            })
          );
          // setTotalCount((pre) => pre + length);
          // setTotalCount(length + lotCount+ styCount+ poCount)
        })
        .catch((error) => {
          console.error("Error occurs with Sup files", error);
        });

      axios
        .get(process.env.REACT_APP_API_LINK + "/api/v1/admin/getStyFiles")
        .then((res) => {
          const length = res.data.list.length;
          // console.log("sty length ", length);
          //const prevStyValue = parseInt(localStorage.getItem('prevSty'), 10);

          setStyCount(length);

          localStorage.setItem("prevSty", length);
          setStyList(
            res.data.list.map((x) => {
              return { value: x, label: x };
            })
          );
          // setTotalCount((pre) => pre + length);
          // setTotalCount(length + lotCount+ poCount+ supCount)
        })
        .catch((error) => {
          console.error("Error occurs with Sty files", error);
        });
    }
  }, [userData, isSubmitOrDeleteForOthers]);

  useLayoutEffect(() => {
    if (
      poCount !== null &&
      lotCount !== null &&
      styCount !== null &&
      supCount !== null
    ) {
      setIsShow(true);
      setTotalCount(poCount + lotCount + styCount + supCount);
    }
  }, [poCount, lotCount, styCount, supCount]);

  const handleSubmit = async (option) => {
    // if (selectedFile.length === 0) {
    //   return toast.error("no file selected");
    // }
    // console.log('selectedpo list, ',polist)
    if (option === "json") setIsLoading(true);
    if (
      window.confirm(
        `Selected File:\n ${selectedFile.map(
          (file) => `${file.label}\n`
        )} \nWill get uploaded  Are you sure?`
      )
    ) {
      const fD = new FormData();
      fD.append("method", method);
      if (method === "drag") fD.append("file", selectedFile);
      else
        fD.append("fileName", JSON.stringify(selectedFile.map((x) => x.value)));

      let uploadPath = "";
      if (option === "json") {
        uploadPath = "/api/v1/admin/loadPos";
      } else if (option === "lot") {
        uploadPath = "/api/v1/admin/loadLots";
      } else if (option === "sup") {
        uploadPath = "/api/v1/admin/loadSupplyMaster";
      } else {
        uploadPath = "/api/v1/admin/loadStyleMaster";
      }
      axios
        .post(process.env.REACT_APP_API_LINK + uploadPath, fD)
        .then((res) => {
          // console.log("Before setIsLoading(false)");
          if (option === "json") setIsLoading(false);
          // console.log("resssss", res.data);

          // if (option === "json") {
          //   if (res.data.failureList.length > 0) {
          //     //setIsLoading(false);
          //     return toast.error(
          //       res.data.failureList.map((x) => `${x.file}\n`) +
          //         "\nFailed because entry has started"
          //     );
          //   }

          //   if (res.data.successList.length > 0) {
          //     //setIsLoading(false);
          //     toast.success(
          //       res.data.successList.map((x) => `${x}\n`) + "  \nLoaded"
          //     );
          //   }
          // }
          toast.success("PO Loading operation Done");
          setSelectedFile(null);
          setMethod("");
          //window.location.reload(false);
          if (option === "json") {
            setIsSubmitOrDelete((prev) => !prev);
          } else {
            setIsSubmitOrDeleteForOthers((prev) => !prev);
          }
          // console.log("After alerts");
          //setIsLoading(false);
        })
        .catch((err) => {
          if (option === "json") setIsLoading(false);
          // console.log(err);
          toast.error("Something went wrong!");
        });
    } else {
      toast.error("Cancelled");
      if (option === "json") setIsLoading(false);
    }
  };

  const selectFieldStyle = {
    width: "300px",
    margin: " 0 auto",
  };

  const handleDelete = (option) => {
    // e.preventDefault();
    setIsLoadingDelete(true);
    if (
      window.confirm(
        `Selected File:\n ${selectedFile.map(
          (file) => `${file.label}\n`
        )} will get deleted \n Are you sure ?`
      )
    ) {
      const fD = new FormData();
      fD.append("type", "po");
      fD.append("fileName", JSON.stringify(selectedFile.map((x) => x.value)));
      axios
        .post("/admin/deleteFiles", fD)
        .then((res) => {
          setIsLoadingDelete(false);
          toast.success("File Deleted!");
          setSelectedFile(null);
          setMethod("");
          // console.log(res.data.failureList, "failure");
          // console.log(res.data.successList, "success");

          // window.location.reload(false);
          setIsSubmitOrDelete((prev) => !prev);
        })
        .catch((err) => {
          setIsLoadingDelete(false);
          // console.log(err);
          toast.error("Something went wrong!");
        });
    } else {
      setIsLoadingDelete(false);
      toast.error("Cancelled");
    }
  };

  const customStyles = {
    // for scrollbar in selected po's
    // menu: (provided) => ({
    //   ...provided,
    //   maxHeight: '200px', // Set the maximum height for the dropdown menu
    //   overflowY: 'auto', // Enable vertical scrolling
    // }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: "110px", // Set the maximum height for the selected values container
      overflowY: "auto", // Enable vertical scrolling
    }),
  };

  // console.log("poList", polist);
  // console.log("pocount", poCount);

  return (
    <div>
      <div className="sticky-top">
        <Header />
      </div>
      <div>
      <PoFileLoadingStatus/>
        <Container className="pb-5 pt-3">
          <Row>
            <Col>
              <>
                <h3 className="text-center">Select PO File from server</h3>
                <h6 className="text-center">
                  <Badge bg="danger">{poCount} left</Badge>
                </h6>
              </>

              <Col style={selectFieldStyle}>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  options={polist}
                  value={option === "json" ? selectedFile : null}
                  onChange={(val) => {
                    setSelectedFile(val);
                    setMethod("select");
                    setOption("json");
                  }}
                  styles={customStyles}
                />
              </Col>
              <br />
              <Row className="d-flex justify-content-center text-center align-items-center">
                {method === "" ? (
                  <>
                    <h5>You have not selected any file</h5>
                    <p>Note: Please select file carefully!</p>
                  </>
                ) : (
                  <>
                    {option === "lot" ||
                    option === "sty" ||
                    option === "sup" ||
                    selectedFile.length === 0 ? (
                      <>
                        <h5>You have not selected any file</h5>
                        <p>Note: Please select file carefully!</p>
                      </>
                    ) : (
                      <h5>
                        You have selected{" "}
                        <Badge bg="info">
                          {method === ""
                            ? null
                            : method === "select" && option === "json"
                            ? selectedFile.label
                            : selectedFile.name}
                        </Badge>{" "}
                        File
                      </h5>
                    )}
                  </>
                )}
                <div
                  style={{
                    // border: "2px solid red",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    width: widthCheck,
                  }}
                >
                  <Button
                    variant="primary"
                    size="sm"
                    disabled={
                      method === "" ||
                      selectedFile.length === 0 ||
                      option === "lot" ||
                      option === "sty" ||
                      option === "sup"
                    }
                    onClick={() => handleSubmit("json")}
                  >
                    {isLoading ? <Spinner size="sm" /> : "Process"}
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    disabled={method === "" || selectedFile.length === 0}
                    onClick={() => handleDelete("json")}
                  >
                    {isLoadingDelete ? <Spinner size="sm" /> : "Delete"}
                  </Button>
                </div>
              </Row>
            </Col>
            {!!userData.admin && (
              <>
                <Col>
                  {/* <h1 className="mx-auto text-center">Or</h1> */}

                  <>
                    <h3 className="text-center">Select LOT File from server</h3>
                    {
                      <h6 className="text-center">
                        <Badge bg="danger ">{lotCount} left</Badge>
                      </h6>
                    }
                  </>

                  <Col style={selectFieldStyle}>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      options={lotList}
                      value={option === "lot" ? selectedFile : null}
                      onChange={(val) => {
                        setSelectedFile(val);
                        setOption("lot");
                        setMethod("select");
                      }}
                    />
                  </Col>
                  <br />
                  <Row className="d-flex justify-content-center text-center align-items-center">
                    {method === "" ? (
                      <>
                        <h5>You have not selected any file</h5>
                        <p>Note: Please select file carefully!</p>
                      </>
                    ) : option === "json" ||
                      option === "sty" ||
                      option === "sup" ||
                      selectedFile.length === 0 ? (
                      <>
                        <h5>You have not selected any file</h5>
                        <p>Note: Please select file carefully!</p>
                      </>
                    ) : (
                      <>
                        <h5>
                          You have selected{" "}
                          <Badge bg="info">
                            {method === ""
                              ? null
                              : method === "select" && option === "lot"
                              ? selectedFile.label
                              : selectedFile.name}
                          </Badge>{" "}
                          File
                        </h5>
                      </>
                    )}

                    <Button
                      variant="primary"
                      size="sm"
                      disabled={
                        method === "" ||
                        selectedFile.length === 0 ||
                        option === "json" ||
                        option === "sty" ||
                        option === "sup"
                      }
                      onClick={() => handleSubmit("lot")}
                      className=""
                    >
                      Process
                    </Button>
                  </Row>
                </Col>
                <Col>
                  <>
                  <h3 className="text-center">Select STY File from server </h3>
                    <h6 className="text-center">
                      <Badge bg="danger">{styCount} left</Badge>
                    </h6>
                  </>

                  <Col style={selectFieldStyle}>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      options={styList}
                      value={option === "sty" ? selectedFile : null}
                      onChange={(val) => {
                        setSelectedFile(val);
                        setMethod("select");
                        setOption("sty");
                      }}
                    />
                  </Col>
                  <br />
                  <Row className="d-flex justify-content-center text-center align-items-center">
                    {method === "" ? (
                      <>
                        <h5>You have not selected any file</h5>
                        <p>Note: Please select file carefully!</p>
                      </>
                    ) : option === "json" ||
                      option === "lot" ||
                      option === "sup" ||
                      selectedFile.length === 0 ? (
                      <>
                        <h5>You have not selected any file</h5>
                        <p>Note: Please select file carefully!</p>
                      </>
                    ) : (
                      <>
                        <h5>
                          You have selected{" "}
                          <Badge bg="info">
                            {method === ""
                              ? null
                              : method === "select" && option === "sty"
                              ? selectedFile.label
                              : selectedFile.name}
                          </Badge>{" "}
                          File
                        </h5>
                      </>
                    )}

                    <Button
                      variant="primary"
                      size="sm"
                      disabled={
                        method === "" ||
                        selectedFile.length === 0 ||
                        option === "lot" ||
                        option === "json" ||
                        option === "sup"
                      }
                      onClick={() => handleSubmit("sty")}
                      className=""
                    >
                      Process
                    </Button>
                  </Row>
                </Col>
                <Col>
                  <>
                    <h3 className="text-center">Select SUP File from server</h3>
                    <h6 className="text-center">
                      <Badge bg="danger">{supCount} left</Badge>
                    </h6>
                  </>

                  <Col style={selectFieldStyle}>
                    <Select
                      isMulti={true}
                      closeMenuOnSelect={false}
                      options={supList}
                      value={option === "sup" ? selectedFile : null}
                      onChange={(val) => {
                        setSelectedFile(val);
                        setMethod("select");
                        setOption("sup");
                      }}
                    />
                  </Col>
                  <br />
                  <Row className="d-flex justify-content-center text-center align-items-center">
                    {method === "" ? (
                      <>
                        <h5>You have not selected any file</h5>
                        <p>Note: Please select file carefully!</p>
                      </>
                    ) : option === "json" ||
                      option === "sty" ||
                      option === "lot" ||
                      selectedFile.length === 0 ? (
                      <>
                        <h5>You have not selected any file</h5>
                        <p>Note: Please select file carefully!</p>
                      </>
                    ) : (
                      <h5>
                        You have selected{" "}
                        <Badge bg="info">
                          {method === ""
                            ? null
                            : method === "select" && option === "sup"
                            ? selectedFile.label
                            : selectedFile.name}
                        </Badge>{" "}
                        File
                      </h5>
                    )}

                    <Button
                      variant="primary"
                      size="sm"
                      disabled={
                        method === "" ||
                        selectedFile.length === 0 ||
                        option === "lot" ||
                        option === "sty" ||
                        option === "json"
                      }
                      onClick={() => handleSubmit("sup")}
                      className=""
                    >
                      Process
                    </Button>
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default MyDropzone;
