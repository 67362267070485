import React, { useEffect, useState, useContext } from "react";
import Header from "../../../components/header";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import convertHeader from "../../../utility/convertHeader";

import InputBox from "./InputBox";
import Context from "../../../context/context";
import Weights from "./Weights";

const ModificationForm = () => {
  const navigate = useNavigate();
  const { seqId } = useParams();
  const [newHeader, setNH] = useState([]);
  const [weightData, setWD] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);

  const ctx = useContext(Context);
  // const [isDeleted, setIsDeleted] = useParams(false);
  useEffect(() => {
    ctx.setBoxData([]);
    axios
      .get(process.env.REACT_APP_API_LINK + "/api/v1/entry/get?id=" + seqId, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // console.log("entry by id", res);
        const purchaseOrderNumber = parseInt(
          res.data.boxData[0].purchaseOrder.TOT_QTY,
          10
        );
        // console.log('purchase order', purchaseOrderNumber)
        setTotalQuantity(purchaseOrderNumber);
        ctx.setBoxData(res.data.boxData);
        ctx.setOvershipValue(res.data.overshipValue);
        setNH(convertHeader(res.data.boxData[0].purchaseOrder.SIZE));
        setWD(res.data.entry);
        // ctx.entryBySeqID(res.data);
        // console.log("entry By Id", res.data);
      })
      .catch((err) => {
        // console.log(err)
        alert("No Data found");
        navigate(-1);
      });
  }, []);

  // console.log("total quantity", totalQuantity);

  return (
    <>
      <div style={{ position: "sticky", top: "0px" }}>
        <Header />
      </div>
      <div
        style={{
          position: "sticky",
          top: "86px",
          // border: "2px solid red",
          zIndex: 1,
          background: "whitesmoke",
          padding: ".5%",
        }}
      >
        <div
          style={{
            // border: "2px solid green",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              // border: "2px solid green",
              width: "150px",
              padding: ".5%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "1%",
            }}
          >
            <button
              type="button"
              className="btn btn-secondary  ms-5"
              onClick={() => navigate("/boxDetails")}
            >
              Back
            </button>
          </div>
          <h1
            className="text-center   text-danger"
            style={{
              // border: "2px solid green",
              width: "67%",
              display: "flex",
              justifyContent: "flex-start",
              color: "red",
            }}
          >
            Box Details Maintenance
          </h1>
        </div>
        <Weights
          weightData={weightData}
          setWeightData={setWD}
          totalQuantity={totalQuantity}
        />
      </div>
      <div
        className="mx-4 mt-2 table-responsive"
        style={{
          // border: "2px solid red",
          height: "43vh",
          background: "whitesmoke",
        }}
      >
        <table
          className="table table-bordered table-hover table-sm "
          style={{
            position: "sticky",
            zIndex: "0",
          }}
        >
          <thead style={{ position: "sticky", top: "0px" }}>
            <tr className="table-warning">
              <th>Info</th>
              <th>Po</th>
              <th>Sea</th>
              <th>Sty</th>
              <th>Lot</th>
              <th>Clr</th>
              <th>Dim</th>
              <th>TOT_QTY</th>
              <th>{newHeader.length !== 0 ? newHeader[0] : "SZ01"}</th>
              <th>{newHeader.length !== 0 ? newHeader[1] : "SZ02"}</th>
              <th>{newHeader.length !== 0 ? newHeader[2] : "SZ03"}</th>
              <th>{newHeader.length !== 0 ? newHeader[3] : "SZ04"}</th>
              <th>{newHeader.length !== 0 ? newHeader[4] : "SZ05"}</th>
              <th>{newHeader.length !== 0 ? newHeader[5] : "SZ06"}</th>
              <th>{newHeader.length !== 0 ? newHeader[6] : "SZ07"}</th>
              <th>{newHeader.length !== 0 ? newHeader[7] : "SZ08"}</th>
              <th>{newHeader.length !== 0 ? newHeader[8] : "SZ09"}</th>
              <th>{newHeader.length !== 0 ? newHeader[9] : "SZ10"}</th>
              <th>{newHeader.length !== 0 ? newHeader[10] : "SZ11"}</th>
              <th>{newHeader.length !== 0 ? newHeader[11] : "SZ12"}</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {ctx.boxData.map((box, i) => {
              return (
                <InputBox box={box} newHeader={newHeader} index={i} key={i} />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ModificationForm;
