module.exports = function verifySizeHeading(data) {
  for (let i = 1; i < data.length; i++) {
    if (
      !(
        data[0].PO === data[i].PO &&
        data[0].SEA === data[i].SEA &&
        data[0].STY ===data[i].STY
      )
    )
      return false;
  }

  return true;
};
