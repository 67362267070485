import React, { useEffect, useState } from "react";
import { Col, Spinner } from "react-bootstrap";
import axios from "axios";
import "./POSummary.css";
import Header from "../../components/header";
import Context from "../../context/context";
import { useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import sortByColumn from "../../utility/sorting";
import { debounce } from "underscore";
import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import convertTimeToCountry from "../../utility/convertTimeToCountry";
import ErrosToast from "../../components/ErrosToast";
import { FiRefreshCcw } from "react-icons/fi";
const PoSummary = () => {
  const ctx = useContext(Context);
  const [changeData, setChangeData] = useState(true);
  const [changeData1, setChangeData1] = useState(true);
  const [changeData2, setChangeData2] = useState(true);
  const [tableData, setTableData] = useState(ctx.poData || []);
  const [uniqueValuesArray, setUVA] = useState([]);
  const [factField, setFactField] = useState("");
  const [styleField, setStyleField] = useState("");
  const [poField, setPoField] = useState("");
  const { userData } = useContext(Context);
  const [isData, setIsData] = useState(false);
  //const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [closedPOstatus, setClosedPOstatus] = useState([]);
  const [emojiOperator, setEmojiOperator] = useState({
    fact: "⬆",
    sup: "⬆",
    agent: "⬆",
    po: "⬆",
    sty: "⬆",
  });
  const [dataInIncrement, setDataInIncrement] = useState(ctx.poSummary);
  const [dataInDecrement, setDataInDecrement] = useState(ctx.poSummary);
  const [showIncrement, setShowIncrement] = useState(true);
  const [isCheckedClosePo, setIsCheckedClosePo] = useState(false); // checked or uncheck for close po check box
  const [selectedDates, setSelectedDates] = useState([]); // for store and modify to all etd dates in this
  const [selectedEtdFrom, setSelectedEtdFrom] = useState(null); // to filter etdFrom date
  const [selectedEtdTo, setSelectedEtdTo] = useState(null); // to filter etdTo date
  const [isOvershipDisabled, setIsOvershipDisabled] = useState(
    ctx.poData.map(() => true)
  ); // to set each overship disabled or not
  const [overshipData, setOvershipData] = useState(
    ctx.poData.map((po) => po.defaultOvership) || []
  ); //for store and modify to all overships in this
  const [isDateDisabled, setIsDateDisabled] = useState([]); // to set each etdDate disabled or not

  const [isUpdatePoSummary, setIsUpdatePoSummary] = useState(false); // useEffect will trigger everytime we update overship or etd

  const [isOvershipLoading, setIsOvershipLoading] = useState(false); // for button loading in overship
  const [isEtdLoading, setIsEtdLoading] = useState(false); // for button loading in etd

  const [isClosePoStatus, setIsClosePoStatus] = useState(false); // to check is po close button is trigger or not

  const [dateInputValue, setDateInputValue] = useState([]);

  const [totalData, setTotalData] = useState({
    // to show total data in table header
    totQty: 0,
    totPck: 0,
    totBal: 0,
    totExFactBox: 0,
    totShipLoadBox: 0,
    totExFactQty: 0,
    totShipQty: 0,
    totTransferBox: 0,
    totTransferQty: 0,
  });

  const [isPoENteriesDelte, setisPoENteriesDelte] = useState(false); // for poEnteries  delete button lading status
  // const requestSort = (key) => {
  //   let direction = "asc";

  //   if (sortConfig.key === key && sortConfig.direction === "asc") {
  //     direction = "desc";
  //   }
  //   setSortConfig({ key, direction });
  // };
  // console.log("checking close po or not", isCheckedClosePo);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setUVA(ctx.setPoSummary);
        const res = await axios.get(`/po/getPoSummary?closed=${isCheckedClosePo}`);
        const formattedDates = res.data.purchaseOrders.map((dateString) => {
          if (!dateString.etd) return "";
          const isoDate = new Date(dateString.etd);
          return isNaN(isoDate) ? "" : isoDate.toISOString().split("T")[0];
        });
        
        const disableOveships = res.data.purchaseOrders.map(data => 
          data.overship || data.overship === 0 || ctx.userData.defaultOvership ? true : false
        );
        
        const overships = res.data.purchaseOrders.map(data =>
          data.overship || data.overship === 0 ? data.overship : ctx.userData.defaultOvership || ""
        );
        
        const disableDates = res.data.purchaseOrders.map(data => data.etd ? true : false);
        const allFalse = Array(res.data.purchaseOrders.length).fill(false);
  
        // Set local states
        setIsDateDisabled(disableDates);
        setOvershipData(overships);
        setSelectedDates(formattedDates);
        setIsOvershipDisabled(disableOveships);
        setIsOvershipLoading(allFalse);
        setIsEtdLoading(allFalse);
        setDataInIncrement(res.data.purchaseOrders);
        setDataInDecrement(res.data.purchaseOrders);
        setUVA(res.data.purchaseOrders);
        setIsData(true);
  
        // Update context state in a separate effect
        ctx.setPoSummary(res.data.purchaseOrders);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [isUpdatePoSummary, isClosePoStatus, isPoENteriesDelte]);

  useEffect(() => {
    // for total sum in the header of tot_qty, tot_pck, tot_bal, exFactory and shipLoading
    const real = dataInIncrement.reduce(
      (acc, data) => {
        acc.totQty += parseInt(data?.totalQuantity || 0);
        acc.totPck += parseInt(data?.totalPacked || 0);
        acc.totBal += parseInt(data?.totalBalance || 0);
        acc.totExFactBox += parseInt(data?.totalExFactory || 0);
        acc.totShipLoadBox += parseInt(data?.totalShipLoading || 0);
        acc.totExFactQty += parseInt(data?.totalExFactoryData || 0);
        acc.totShipQty += parseInt(data?.totalShipData || 0);
        acc.totTransferBox += parseInt(data?.totalTranferDataBox || 0);
        acc.totTransferQty += parseInt(data?.totalTranferDataQTy || 0);
        return acc;
      },
      {
        totQty: 0,
        totPck: 0,
        totBal: 0,
        totExFactBox: 0,
        totShipLoadBox: 0,
        totExFactQty: 0,
        totShipQty: 0,
        totTransferBox: 0,
        totTransferQty: 0,
      }
    ); // Initialize accumulator properties

    // console.log('real one ', real);
    setTotalData(real);
  }, [dataInIncrement]);
  // console.log('po summary data', ctx.poSummary)

  const downloadPdf = (po, fact) => {
    axios
      // .get(`/po/getPdf?po=${po}&fact=${fact}`,
      .get(`/po/getPdf?po=${po}`, {
        responseType: "blob", // Ensure that the response is treated as a binary blob
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${po}.pdf`);
        document.body.appendChild(link);
        // Trigger the download
        link.click();
        // Clean up the URL and link
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((err) => {
        alert("No entry found");
      });
  };
  // console.log("outside closePO", isCheckedClosePo);
  // console.log('selected from newwwwwwwww', selectedEtdFrom)
  const HandleFilter = (
    e,
    fact,
    sty,
    po,
    isCheckedClosePo,
    selectedEtdFrom,
    selectedEtdTo
  ) => {
    if (e) {
      e.preventDefault();
    }
    // e.preventDefault();
    let newSelectedEtdFrom = selectedEtdFrom === null ? "" : selectedEtdFrom;
    let newSelectedEtdTo = selectedEtdTo === null ? "" : selectedEtdTo;
    if (selectedEtdTo && selectedEtdFrom > selectedEtdTo) {
      toast.remove();
      ErrosToast(`ETD to cannot be less than etd from`);
      setSelectedEtdTo("");
      // setIsData(true);
      // newSelectedEtdFrom = "";
      // newSelectedEtdTo = "";
      return;
    }

    // console.log("inside isCheckedClosePo", isCheckedClosePo);

    // console.log("etdfrom from filter", newSelectedEtdFrom);
    else {
      setIsData(false);
      // const encodeQueryParam = (param) => encodeURIComponent(param).replace(/%/g, '%25');
      const encodedFact = encodeURIComponent(fact);
      const encodedPO = encodeURIComponent(po);
      const encodedSty = encodeURIComponent(sty);
      const encodedIsCheckedClosePo = encodeURIComponent(isCheckedClosePo);
      const encodedFromEtd = encodeURIComponent(newSelectedEtdFrom);
      const encodedToEtd = encodeURIComponent(newSelectedEtdTo);
      axios
        .get(
          `/po/getPoSummary?FACT=${encodedFact}&STY=${encodedSty}&closed=${encodedIsCheckedClosePo}&fromEtd=${encodedFromEtd}&toEtd=${encodedToEtd}&PO=${encodedPO}`
        )
        .then((res) => {
          const formattedDates = res.data.purchaseOrders.map((dateString) => {
            // console.log('getting date',dateString.etd)
            if (!dateString.etd) {
              return ""; // Return empty string for null or undefined values
            }
            const isoDate = new Date(dateString.etd);
            // console.log('isoDate', isoDate)
            if (isNaN(isoDate)) {
              console.error(`Invalid date format: ${dateString.etd}`);
              return ""; // Return empty string for invalid date strings
            }
            return isoDate.toISOString().split("T")[0];
          });
          // console.log('formatted dates', formattedDates)
          const disableOveships = res.data.purchaseOrders.map((data) => {
            return data.overship || ctx.userData.defaultOvership ? true : false;
          });
          const overships = res.data.purchaseOrders.map((data) => {
            return data.overship
              ? data.overship
              : ctx.userData.defaultOvership || "";
          });
          const disableDates = res.data.purchaseOrders.map((data) => {
            return data.etd ? true : false;
          });
          const allFalse = Array(res.data.purchaseOrders.length).fill(false);
          setIsDateDisabled(disableDates);
          setOvershipData(overships);
          setSelectedDates(formattedDates);
          setIsOvershipDisabled(disableOveships);
          setIsOvershipLoading(allFalse);

          ctx.setPoSummary(res.data.purchaseOrders);
          setDataInIncrement(res.data.purchaseOrders);
          setDataInDecrement(res.data.purchaseOrders);
          setUVA(res.data.purchaseOrders);
          setIsData(true);
          setClosedPOstatus(res.data.purchaseOrders);
          //   let x = setUVA(res.data.purchaseOrders);
        })
        .catch((err) => {
          setIsData(true);
          // console.log("error: ", err);
        });
    }
  };
  const OnChangeHandleFilter = useCallback(
    debounce(
      (e, fact, sty, po, isCheckedClosePo, selectedEtdFrom, selectedEtdTo) =>
        HandleFilter(
          e,
          fact,
          sty,
          po,
          isCheckedClosePo,
          selectedEtdFrom,
          selectedEtdTo,
          poField
        ),
      1000,
      false
    ),
    []
  );
  // useEffect(()=> {
  //   if(uniqueValuesArray){
  //     const filteredData = sortByColumn(uniqueValuesArray, 'PO', emojiOperator.po);
  //     //setDataInIncrement(filteredData)
  //     emojiOperator.po === '⬆' ? setDataInDecrement(filteredData) : setDataInIncrement(filteredData)
  //   }
  // },[])
  // console.log(dataInDecrement);
  // console.log(dataInIncrement);
  const emojiOperatorChange = (e) => {
    const name = e.target.dataset.name;
    if (name === "fact") {
      setEmojiOperator({
        ...emojiOperator,
        fact: emojiOperator.fact === "⬆" ? "⬇" : "⬆",
      });
      const filteredData = sortByColumn(
        uniqueValuesArray,
        "FACT",
        emojiOperator.fact
      );
      emojiOperator.fact === "⬆"
        ? setDataInDecrement(filteredData)
        : setDataInIncrement(filteredData);
      emojiOperator.fact === "⬆"
        ? setShowIncrement(true)
        : setShowIncrement(false);
    } else if (name === "agent") {
      setEmojiOperator({
        ...emojiOperator,
        agent: emojiOperator.agent === "⬆" ? "⬇" : "⬆",
      });
      const filteredData = sortByColumn(
        uniqueValuesArray,
        "AGNT",
        emojiOperator.agent
      );
      emojiOperator.agent === "⬆"
        ? setDataInDecrement(filteredData)
        : setDataInIncrement(filteredData);
      emojiOperator.agent === "⬆"
        ? setShowIncrement(true)
        : setShowIncrement(false);
    } else if (name === "po") {
      setEmojiOperator({
        ...emojiOperator,
        po: emojiOperator.po === "⬆" ? "⬇" : "⬆",
      });
      const filteredData = sortByColumn(
        uniqueValuesArray,
        "PO",
        emojiOperator.po
      );
      emojiOperator.po === "⬆"
        ? setDataInDecrement(filteredData)
        : setDataInIncrement(filteredData);
      emojiOperator.po === "⬆"
        ? setShowIncrement(true)
        : setShowIncrement(false);
    } else if (name === "sty") {
      setEmojiOperator({
        ...emojiOperator,
        sty: emojiOperator.sty === "⬆" ? "⬇" : "⬆",
      });
      const filteredData = sortByColumn(
        uniqueValuesArray,
        "STY",
        emojiOperator.sty
      );
      emojiOperator.sty === "⬆"
        ? setDataInDecrement(filteredData)
        : setDataInIncrement(filteredData);
      emojiOperator.sty === "⬆"
        ? setShowIncrement(true)
        : setShowIncrement(false);
    } else if (name === "sup") {
      setEmojiOperator({
        ...emojiOperator,
        sup: emojiOperator.sup === "⬆" ? "⬇" : "⬆",
      });
      const filteredData = sortByColumn(
        uniqueValuesArray,
        "SUP",
        emojiOperator.sup
      );
      emojiOperator.sup === "⬆"
        ? setDataInDecrement(filteredData)
        : setDataInIncrement(filteredData);
      emojiOperator.sup === "⬆"
        ? setShowIncrement(true)
        : setShowIncrement(false);
    }
  };
  const handleClosePoCheckboxChange = () => {
    let newSelectedEtdFrom = selectedEtdFrom === null ? "" : selectedEtdFrom;
    let newSelectedEtdTo = selectedEtdTo === null ? "" : selectedEtdTo;
    setIsData(false);
    setIsCheckedClosePo((prevChecked) => !prevChecked);
    setUVA(ctx.setPoSummary);
    // console.log("Fact field inside", factField);
    // console.log("styleField inside", styleField);
    axios
      .get(
        `/po/getPoSummary?closed=${!isCheckedClosePo}&FACT=${factField}&STY=${styleField}&fromEtd=${newSelectedEtdFrom}&toEtd=${newSelectedEtdTo}&PO=${poField}`
      )
      .then((res) => {
        // console.log("line407", res);

        const formattedDates = res.data.purchaseOrders.map((dateString) => {
          // console.log('getting date',dateString.etd)
          if (!dateString.etd) {
            return ""; // Return empty string for null or undefined values
          }
          const isoDate = new Date(dateString.etd);
          // console.log('isoDate', isoDate)
          if (isNaN(isoDate)) {
            console.error(`Invalid date format: ${dateString.etd}`);
            return ""; // Return empty string for invalid date strings
          }
          return isoDate.toISOString().split("T")[0];
        });
        // console.log('formatted dates', formattedDates)
        const disableOveships = res.data.purchaseOrders.map((data) => {
          return data.overship || ctx.userData.defaultOvership ? true : false;
        });
        const overships = res.data.purchaseOrders.map((data) => {
          return data.overship
            ? data.overship
            : ctx.userData.defaultOvership || "";
        });
        const disableDates = res.data.purchaseOrders.map((data) => {
          return data.etd ? true : false;
        });
        const allFalse = Array(res.data.purchaseOrders.length).fill(false);

        setIsDateDisabled(disableDates);
        setOvershipData(overships);
        setSelectedDates(formattedDates);
        setIsOvershipDisabled(disableOveships);
        setIsOvershipLoading(allFalse);

        ctx.setPoSummary(res.data.purchaseOrders);
        setDataInIncrement(res.data.purchaseOrders);
        setDataInDecrement(res.data.purchaseOrders);
        setUVA(res.data.purchaseOrders);
        setIsData(true);
        // setClosedPOstatus(res.data.purchaseOrders);
      });
  };
  const handleClosePo = (PO) => {

    if (window.confirm(`Are you Sure , you want to close PO: ${PO}? `)) {
      setIsData(false);
      axios
        .get(`/po/closePo?PO=${PO}`)
        .then((res) => {
          // console.log("close po result", res);
          setIsClosePoStatus((prev) => !prev);
          toast.remove();
          toast.success("po closed");
          if (res.data.status === "Success") {
            axios
              .post("/po/getFilteredPurchaseOrders", {
                ...ctx.id,
                open: ctx.openCheck,
                processed: ctx.procesCheck,
                pageNo: 0,
                docNo: 30,
                order: [
                  ["FACT", "ASC"],
                  ["SUP", "ASC"],
                  ["AGNT", "ASC"],
                  ["PO", "ASC"],
                  ["ETA", "ASC"],
                ],
              })
              .then((res) => {
                ctx.setPoDataDSP(res.data.poData);
                ctx.setPageCount(Math.ceil(res.data.pageCount));
                setFactField("");
                setStyleField("");
                setSelectedEtdFrom(null);
                setSelectedEtdTo(null);
                ctx.setSelectedPo("");
                // let NewdspPoData = ctx.setPoDataDSP(res.data.poData);
                // let dspDataAfterClosePO = NewdspPoData.filter(
                //   (el) => el.closed == false
                // );
                // ctx.setPoDataDSP(dspDataAfterClosePO);
                // console.log("resaaaa", res);
              });
          }
        })
        .catch((err) => {
          console.log(err)
          setIsData(true);
          // console.log(err);
        });
    } else {
      //toast.error('cancelled');
    }
  };

  const handleOpenPO = (PO) => {

    if (window.confirm(`Are you Sure , you want to open PO: ${PO}? `)) {
      setIsData(false);
      axios
        .get(`/po/openPo?PO=${PO}`)
        .then((res) => {
          // console.log("open po result", res);
          setIsClosePoStatus((prev) => !prev);
          toast.remove();
          toast.success("po opened");

          if (res.data.status === "Success") {
            axios
              .post("/po/getFilteredPurchaseOrders", {
                ...ctx.id,
                open: ctx.openCheck,
                processed: ctx.procesCheck,
                pageNo: 0,
                docNo: 30,
                order: [
                  ["FACT", "ASC"],
                  ["SUP", "ASC"],
                  ["AGNT", "ASC"],
                  ["PO", "ASC"],
                  ["ETA", "ASC"],
                ],
              })
              .then((res) => {
                ctx.setPoDataDSP(res.data.poData);
                ctx.setPageCount(Math.ceil(res.data.pageCount));
                // console.log("resaaaa", res);
              });
          }
        })
        .catch((err) => {
          console.log(err)
          setIsData(true);
        });
    } else {
      //toast.error('cancelled');
    }
  };

  const handleDateChange = (date, index) => {
    const updatedDates = [...selectedDates];
    const newDate = convertLocalToUTCDate(date);
    updatedDates[index] = newDate;
    setSelectedDates(updatedDates);
  };

  function convertUTCToLocalDate(date) {
    if (!date) {
      return date;
    }
    date = new Date(date);
    date = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );
    return date;
  }

  function convertLocalToUTCDate(date) {
    if (!date) {
      return date;
    }
    date = new Date(date);
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return date;
  }

  const handleEtdDate = (name, date, e) => {
    // console.log("etd name", name);
    const { value } = e.target;
    // console.log("value for e", value);
    let newFormattedDate;

    // let formattedValue;
    // if(value && (!value.includes('/') || !value.includes('-'))){
    //   const inputValue = e.target.value;
    //   formattedValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    //   if (formattedValue.length > 2 && formattedValue.length < 5) {
    //     formattedValue = `${formattedValue.slice(0, 2)}-${formattedValue.slice(2)}`;
    //   } else if (formattedValue.length > 4) {
    //     formattedValue = `${formattedValue.slice(0, 2)}-${formattedValue.slice(2, 4)}-${formattedValue.slice(4)}`;
    //   }
    //   e.target.value = formattedValue;
    // }

    if (value && value.length > 3 && !value.includes("-")) {
      toast.remove();
      ErrosToast("date format should be in dd-mm-yyyy");
      return;
    }
    const newDate = convertLocalToUTCDate(date);
    const formattedDate = formatDate(newDate);
    newFormattedDate = formattedDate.split(" ")[0];
    // console.log("new new", newFormattedDate);
    // console.log('type of this new format date', typeof(newFormattedDate))
    // let etdDate = new Date(date);
    // console.log('isoDate', isoDate)
    if (name === "etdFrom") {
      // if(value){
      //   setSelectedEtdFrom(formattedValue)
      // }
      setSelectedEtdFrom(newFormattedDate);
    } else if (name === "etdTo") {
      // if(value){
      //   setSelectedEtdTo(formattedValue)
      // }
      setSelectedEtdTo(newFormattedDate);
    }
  };
  // console.log("etd from", selectedEtdFrom);
  // console.log("etdto", selectedEtdTo);

  const handleEditClick = (i) => {
    const newArray = [...isOvershipDisabled];
    newArray[i] = !newArray[i]; // Toggle the boolean value at the specified index
    setIsOvershipDisabled(newArray);
  };

  const handleOvershipSubmit = (e, el, i, overshipValue) => {
    if (
      overshipValue === null ||
      overshipValue === undefined ||
      overshipValue === ""
    ) {
      overshipValue = 0;
    }

    if (isOvershipDisabled[i]) {
      // Enable the input field for editing
      handleEditClick(i);
    } else {
      // Set the loading state to true
      setIsOvershipLoading((prev) => {
        const newState = [...prev];
        newState[i] = true;
        return newState;
      });

      axios
        .post("/po/updateOvership", {
          data: {
            PO: el.PO,
            overshipValue: parseInt(overshipValue, 10),
          },
        })
        .then((res) => {
          // Update only the specific row in the overshipData state
          setOvershipData((prevData) => {
            const newData = [...prevData];
            newData[i] = parseInt(overshipValue, 10);
            return newData;
          });

          // Switch back to "EDIT" mode after saving
          setIsOvershipDisabled((prev) => {
            const newState = [...prev];
            newState[i] = true;
            return newState;
          });

          setIsOvershipLoading((prev) => {
            const newState = [...prev];
            newState[i] = false;
            return newState;
          });

          toast.remove();
          toast.success("Successfully updated!");
          // console.log("overship result", res);
        })
        .catch((err) => {
          // console.log("something went wrong: ", err);

          setIsOvershipLoading((prev) => {
            const newState = [...prev];
            newState[i] = false;
            return newState;
          });
        });
    }
  };

  // console.log('data overship', overshipData)
  // console.log("close po??", isCheckedClosePo);
  // console.log('is overshipeed', isOvershipDisabled)
  // console.log("selected etd from date", selectedEtdFrom);

  const handleEtdEditClick = (i) => {
    const newArray = [...isDateDisabled];
    newArray[i] = !newArray[i]; // Toggle the boolean value at the specified index
    setIsDateDisabled(newArray);
  };

  function formatDate(date) {
    if (!date) {
      return "";
    }
    const isoDate = date.toISOString(); // Get ISO 8601 format
    const formattedDate = isoDate.split("T")[0];
    const formattedTime = isoDate.split("T")[1].split(".")[0];
    return `${formattedDate} ${formattedTime}`;
  }

  const handleDateSubmit = (e, el, i, date) => {
    const isCurrentDate = el.etd && date === null;

    if (!date && !isCurrentDate) {
      toast.remove();
      ErrosToast("Please select a date");
      return "";
    } else if (isDateDisabled[i]) {
      // Enable the input field for editing
      handleEtdEditClick(i);
    } else {
      setIsEtdLoading((prev) => {
        const newState = [...prev];
        newState[i] = true;
        return newState;
      });

      const newDate = new Date(date);
      const formattedDate = formatDate(newDate);

      axios
        .post("/po/updateEtd", {
          data: {
            PO: el.PO,
            etd: isCurrentDate ? null : formattedDate,
          },
        })
        .then((res) => {
          // console.log("etd data in return", res.data);

          // Update only the specific row in the tableData state
          setTableData((prevData) => {
            const newData = [...prevData];
            newData[i] = {
              ...newData[i],
              etd: formattedDate,
            };
            return newData;
          });

          setIsDateDisabled((prev) => {
            const newState = [...prev];
            newState[i] = true; // Switch back to "EDIT" mode
            return newState;
          });

          setIsEtdLoading((prev) => {
            const newState = [...prev];
            newState[i] = false;
            return newState;
          });

          toast.remove();
          toast.success("Successfully updated!");
        })
        .catch((err) => {
          toast.remove();
          ErrosToast("Something went wrong!");
          // console.log("Something went wrong: ", err);

          setIsEtdLoading((prev) => {
            const newState = [...prev];
            newState[i] = false;
            return newState;
          });
        });
    }
  };

  const currentData = showIncrement ? dataInDecrement : dataInIncrement;
  //rgba(255, 255, 255, 0.15)
  // console.log("etd from", selectedEtdFrom);

  // console.log('selected date', selectedDates)
  // console.log("overshipssss", overshipData);

  const handlePoEntriesDelete = async (
    PO,
    packQuantity,
    exFactory,
    shipLoading
  ) => {
    if (packQuantity == 0) {
      toast.remove();
      ErrosToast(`PO ${PO} has not any entery started yet`);
      return;
    } else if (shipLoading > 0) {
      toast.remove();
      ErrosToast(
        `PO ${PO}  is in ShipLoading State,So its not Allowed to delete`
      );
      return;
    } else if (
      window.confirm(
        `${
          exFactory > 0
            ? `Are you sure you want to Delete entire entries of PO ${PO} Generated  barcode wil go waste`
            : `Are you sure you want to Delete entire entries of PO ${PO}`
        } `
      )
    ) {
      // setisPoENteriesDelte(true);
      setIsData(false);
      axios
        .post("/admin/resetPO", { PO: PO })
        .then((res) => {
          // console.log("resssss", res);
          // console.log("Response of poEnteries DELETE api", res.data);

          if (res.data.status === "Success") {
            toast.remove();
            toast.success(`PO ${PO} Entries Deleted`);
            axios
              .post("/po/getFilteredPurchaseOrders", {
                ...ctx.id,
                open: ctx.openCheck,
                processed: ctx.procesCheck,
                pageNo: 0,
                docNo: 30,
                order: [
                  ["FACT", "ASC"],
                  ["SUP", "ASC"],
                  ["AGNT", "ASC"],
                  ["PO", "ASC"],
                  ["ETA", "ASC"],
                ],
              })
              .then((res) => {
                ctx.setPoDataDSP(res.data.poData);
                ctx.setPageCount(Math.ceil(res.data.pageCount));
                setFactField("");
                setStyleField("");
                setSelectedEtdFrom(null);
                setSelectedEtdTo(null);
                ctx.setSelectedPo("");
                setisPoENteriesDelte((prev) => !prev);
              });
          }
        })
        .catch((err) => {
          // if (err.data.message === "PO is on ex-factory state") {
          //   toast.remove();
          //   toast.error(` ${PO} is in Ship Loading State it cannot be deleted`);
          // }
          setIsData(true);
          // console.log("errr", err);
        });
      // setisPoENteriesDelte(false);
    } else {
      // console.log("no");
      // setisPoENteriesDelte(false);
    }
  };
  // console.log("current data", currentData);

  const ExcelButton = ({ PO, STY }) => {
    const [generating, setGenerating] = useState(false);
    // console.log('style ', STY)

    const handleDownloadExcel = () => {
      setGenerating(true);
      axios
        .get(
          `${process.env.REACT_APP_API_LINK}/api/v1/csv/clrWiseSummary?PO=${PO}&STY=${STY}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          window.location.replace(
            process.env.REACT_APP_API_LINK +
              "/api/v1/csv/download?oneTimeToken=" +
              res.data.downloadToken
          );
          setGenerating(false);
        })
        .catch((err) => {
          // console.log(err);
          setGenerating(false);
          if (err.response.status === 403) {
            alert("Something went wrong!");
          }
          alert("Something went wrong!");
        });
    };

    return (
      <button
        className="btn btn-none "
        onClick={() => {
          handleDownloadExcel();
        }}
        disabled={generating}
      >
        {generating ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-download"
            viewBox="0 0 16 16"
          >
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
          </svg>
        )}
      </button>
    );
  };

  return (
    <>
      <div className="sticky-top">
        <Header />
        <div
          style={{
            // background: "#f5f8fa",
            background: "#ffff",
            // border: "2px solid blue",
            padding: "1% 1% 1% 1%",
          }}
          className="d-flex"
        >
          <form
            onSubmit={(e) =>
              HandleFilter(
                e,
                factField,
                styleField,
                poField,
                isCheckedClosePo,
                selectedEtdFrom,
                selectedEtdTo
              )
            }
            className="d-flex mx-4"
          >
            {!!userData.admin && (
              <Col
                lg={2}
                style={{
                  // border: "2px solid green",
                  // width: "17%",
                  textTransform: "capitalize",
                }}
              >
                <label className="fs-6"> Factory :</label>
                <input
                  style={{ width: "88%", textTransform: "capitalize" }}
                  title="Enter Factory to Filter the table"
                  className="form-control border border-dark my-1"
                  type="text"
                  name="fact"
                  maxLength={5}
                  value={factField}
                  // required
                  onChange={(e) => {
                    setFactField(
                      e.target.value.toUpperCase().replace(/%/g, "")
                    );
                    OnChangeHandleFilter(
                      e,
                      e.target.value,
                      styleField,
                      poField,
                      isCheckedClosePo,
                      selectedEtdFrom,
                      selectedEtdTo
                    );
                  }}
                  placeholder="Factory"
                  autoComplete="off"
                />
              </Col>
            )}
            <Col
              lg={2}
              style={{
                // border: "2px solid red",
                // width: "17%",
                textTransform: "capitalize",
              }}
            >
              <label className="fs-6">PO#:</label>
              <input
                style={{ width: "86%" }}
                title="Enter PO Number to Filter the table"
                className="form-control border border-dark my-1"
                type="text"
                name="po"
                maxLength={6}
                value={poField}
                // required
                onChange={(e) => {
                  setPoField(e.target.value.trim(" ").replace(/\D/g, ""));
                  OnChangeHandleFilter(
                    e,
                    factField,
                    styleField,
                    e.target.value.trim(" ").replace(/\D/g, ""),
                    isCheckedClosePo,
                    selectedEtdFrom,
                    selectedEtdTo
                  );
                }}
                placeholder="PO"
                autoComplete="off"
              />
            </Col>
            <Col lg={2} style={{ textTransform: "capitalize" }}>
              <label className="fs-6 "> Style :</label>
              <input
                style={{ width: "88%" }}
                title="Enter Style Number to Filter the table"
                className="form-control border border-dark my-1 "
                type="text"
                name="sty"
                maxLength={8}
                value={styleField}
                // required
                onChange={(e) => {
                  setStyleField(e.target.value.replace(/%/g, ""));
                  OnChangeHandleFilter(
                    e,
                    factField,
                    e.target.value,
                    poField,
                    isCheckedClosePo,
                    selectedEtdFrom,
                    selectedEtdTo
                  );
                }}
                placeholder="Style"
                autoComplete="off"
              />
            </Col>
            {/* <Col className="ms-2 mt-4">
              <input
                className="form-control border border-dark my-2 "
                type="text"
                name="etaFrom"
                maxLength={8}
                // value={}
                // required
                placeholder="ETA From"
              />
            </Col> */}
            <Col
              lg={2}
              style={{
                // border: "2px solid red",
                marginRight: "1%",
              }}
            >
              <label className="fs-6" style={{ textTransform: "capitalize" }}>
                {" "}
                ETD From :
              </label>

              <DatePicker
                className="form-control border border-dark my-1 "
                selected={convertUTCToLocalDate(selectedEtdFrom)}
                onChange={(date, e) => {
                  // console.log('date in onchange', date)
                  // console.log('date set ',e.target.value)
                  handleEtdDate("etdFrom", date, e);
                  const newDate = convertLocalToUTCDate(date);
                  const formattedDate = formatDate(newDate);
                  const newFormattedDate = formattedDate.split(" ")[0];
                  OnChangeHandleFilter(
                    e,
                    factField,
                    styleField,
                    poField,
                    isCheckedClosePo,
                    newFormattedDate,
                    selectedEtdTo
                  );
                }}
                title="Enter date to Filter the table"
                name="etdFrom"
                dateformat="dd-MM-yyyy"
                placeholderText="ETD From"
                showMonthDropdown
                showYearDropdown
                showPopperArrow={false}
                customInput={
                  <MaskedTextInput
                    type="text"
                    dateformat="dd-mm-yyyy"
                    mask={[
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                  />
                }
                autoComplete="off"
                // className="custom-date-picker"
                // disabled={isDateDisabled[i]}
              />
            </Col>
            <Col
              lg={2}
              //  style={{ border: "2px solid green" }}
            >
              <label className="fs-6 " style={{ textTransform: "capitalize" }}>
                {" "}
                ETD To:
              </label>

              <DatePicker
                className="form-control border border-dark my-1 "
                selected={convertUTCToLocalDate(selectedEtdTo)}
                // onChange={(date) => handleEtdDate("etdTo", date)}
                onChange={(date, e) => {
                  handleEtdDate("etdTo", date, e);
                  const newDate = convertLocalToUTCDate(date);
                  const formattedDate = formatDate(newDate);
                  const newFormattedDate = formattedDate.split(" ")[0];

                  OnChangeHandleFilter(
                    e,
                    factField,
                    styleField,
                    poField,
                    isCheckedClosePo,
                    selectedEtdFrom,
                    newFormattedDate
                  );
                }}
                title="Enter date to Filter the table"
                name="etdTo"
                dateformat="dd-MM-yyyy"
                placeholderText="ETD To"
                showMonthDropdown
                showYearDropdown
                showPopperArrow={false}
                customInput={
                  <MaskedTextInput
                    type="text"
                    dateformat="dd-mm-yyyy"
                    mask={[
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                  />
                }
                autoComplete="off"
                // className="custom-date-picker"
                // disabled={isDateDisabled[i]}
              />
            </Col>
            <Col
              lg={1}
              // style={{ border: "2px solid red" }}
            >
              <button
                className="btn btn-primary mx-4 ms-2   text-center"
                type="submit"
                style={{ marginTop: "28px" }}
                onClick={(e) =>
                  HandleFilter(
                    e,
                    factField,
                    styleField,
                    poField,
                    isCheckedClosePo,
                    selectedEtdFrom,
                    selectedEtdTo
                  )
                }
              >
                Filter
              </button>
            </Col>
            {/* reset btn */}
            <Col
              lg={1}
              // style={{ border: "2px solid red" }}
            >
              <button
                className="btn btn-danger mx-4 ms-2 text-center"
                type="submit"
                style={{ marginTop: "28px" }}
                onClick={(e) => {
                  // Reset all input field values
                  setFactField(""); // Reset Factory field
                  setPoField(""); // Reset PO field
                  setStyleField(""); // Reset Style field
                  setSelectedEtdFrom(null); // Reset ETD From field
                  setSelectedEtdTo(null); // Reset ETD To field

                  // Call the filter function with reset values
                  HandleFilter(
                    e,
                    "", // Reset factField to empty string
                    "", // Reset styleField to empty string
                    "", // Reset poField to empty string
                    isCheckedClosePo, // Keep checkbox state
                    null, // Reset ETD From to null
                    null // Reset ETD To to null
                  );
                }}
              >
                Reset
              </button>
            </Col>
          </form>
          <Col
            lg={2}
            style={{
              // border: "2px solid black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0% 0% 0% 0%",
            }}
          >
            <div
              style={{
                // border: " 2px solid blue",
                width: " 3em",
                height: "3.3em",
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                cursor: "pointer",
              }}
            >
              <input
                style={{
                  fontSize: "inherit",
                  width: "2em",
                  height: " 1.2em",
                }}
                title={`${
                  isCheckedClosePo
                    ? "Click to see All opened PO"
                    : "Click to see All Closed PO"
                }`}
                type="checkbox"
                className="me-2"
                name="closedPo"
                value="closedPo"
                checked={isCheckedClosePo}
                onChange={handleClosePoCheckboxChange}
              />
            </div>
            <div
              style={{
                paddingTop: "3%",
                height: "3.9em",
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                // border: "2px solid red",
              }}
            >
              <label className="fw-bold fs-5 ">ClosedPO</label>
            </div>
          </Col>
        </div>
      </div>
      {isData ? (
        <>
          <div
            id="table_data"
            className="mx-4 table-responsive tableFixHead table-container"
            style={{
              marginBottom: "  80px",
              fontSize: "14px",
              height: "64vh",
              // width: "80%",
              margin: "1% 0% 0% 2%",
              // border: "2px solid red",
              position: "relative",
              overflowX: "auto",
            }}
          >
            <table className="table table-bordered table-sm table-header-for-all">
              <thead>
                <tr
                  className="table table-warning "
                  style={{
                    cursor: "pointer",
                    background: "#fff3cd",
                    border: "1px solid black",
                    position: "sticky",
                    top: "0",
                    zIndex: "2",
                    width: "95%",
                  }}
                >
                  <th scope="col">S.No.</th>
                  <th
                    scope="col"
                    data-name="sty"
                    style={{
                      textTransform: "capitalize",
                      // border: "2px solid red",
                    }}
                    onClick={emojiOperatorChange}
                  >
                    {" "}
                    style
                    <div style={{ pointerEvents: "none" }}>
                      {emojiOperator.sty}
                    </div>
                  </th>
                  <th scope="col" data-name="po" onClick={emojiOperatorChange}>
                    PO#
                    <div style={{ pointerEvents: "none" }}>
                      {emojiOperator.po}
                    </div>
                  </th>
                  <th
                    scope="col"
                    data-name="fact"
                    style={{ textTransform: "capitalize" }}
                    onClick={emojiOperatorChange}
                  >
                    Factory{" "}
                    <div style={{ pointerEvents: "none" }}>
                      {emojiOperator.fact}
                    </div>
                  </th>
                  <th
                    scope="col"
                    data-name="sup"
                    style={{ textTransform: "capitalize" }}
                    onClick={emojiOperatorChange}
                  >
                    Supplier
                    <div style={{ pointerEvents: "none" }}>
                      {emojiOperator.sup}
                    </div>
                  </th>
                  <th
                    scope="col"
                    data-name="agent"
                    style={{ textTransform: "capitalize" }}
                    onClick={emojiOperatorChange}
                  >
                    Agent{" "}
                    <div style={{ pointerEvents: "none" }}>
                      {emojiOperator.agent}
                    </div>
                  </th>

                  <th scope="col">For_Cust</th>
                  <th scope="col">
                    <div>Tot_Qty</div>
                    <div>{totalData?.totQty}</div>
                  </th>
                  <th scope="col">
                    <div>Tot_Pck</div>
                    <div>{totalData?.totPck}</div>
                  </th>
                  <th scope="col">
                    <div>Tot_Bal</div>
                    <div>{totalData?.totBal * -1}</div>
                  </th>
                  <th
                    scope="col"
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "0",
                        margin: "0",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "0px",
                        }}
                      >
                        {changeData ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              padding: "0",
                              margin: "0",
                            }}
                          >
                            <div style={{ padding: "0" }}>ExFactory</div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Boxes
                              <button
                                style={{
                                  border: "none",
                                  zIndex: "1",
                                  cursor: "pointer", // Makes it clear the div is clickable
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: 0,
                                  margin: 0,
                                }}
                                onClick={() => {
                                  setChangeData((prev) => !prev);
                                }}
                              >
                                🔃
                              </button>
                            </div>
                            <div style={{ padding: "0" }}>
                              {totalData?.totExFactBox}
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              // border: "2px solid #fff3cd",
                              // width: changeData ? "" : "95px",
                            }}
                          >
                            <div>ExFactory</div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Qty
                              <button
                                style={{
                                  border: "none",
                                  zIndex: "1",
                                  cursor: "pointer", // Makes it clear the div is clickable
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: 0,
                                  margin: 0,
                                }}
                                onClick={() => {
                                  setChangeData((prev) => !prev);
                                }}
                              >
                                🔃
                              </button>
                            </div>
                            <div style={{ padding: "0" }}>
                              {totalData?.totExFactQty}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </th>

                  {/*  */}
                  <th scope="col" style={{ position: "relative", border: "1px black" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "0",
                        margin: "0",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          padding: "0",
                          margin: "0",
                        }}
                      >
                        {changeData1 ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              padding: "0",
                              margin: "0",
                            }}
                          >
                            <div
                              style={{
                                padding: "0",
                              }}
                            >
                              ShipLoading
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Boxes
                              <button
                                style={{
                                  border: "none",
                                  zIndex: "1",
                                  cursor: "pointer", // Makes it clear the div is clickable
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: 0,
                                  margin: 0,
                                }}
                                onClick={() => {
                                  setChangeData1((prev) => !prev);
                                }}
                              >
                                🔃
                              </button>
                            </div>
                            <div style={{ padding: "0" }}>
                              {totalData?.totShipLoadBox}
                            </div>
                          </div>
                        ) : (
                          <div
                            style={
                              {
                                // border: "2px solid #fff3cd",
                                // width: changeData1 ? "" : "128px",
                              }
                            }
                          >
                            <div>ShipLoading</div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Qty
                              <button
                                style={{
                                  border: "none",
                                  zIndex: "1",
                                  cursor: "pointer", // Makes it clear the div is clickable
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: 0,
                                  margin: 0,
                                }}
                                onClick={() => {
                                  setChangeData1((prev) => !prev);
                                }}
                              >
                                🔃
                              </button>
                            </div>
                            <div style={{ padding: "0" }}>
                              {totalData?.totShipQty}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </th>
                  {/*  */}

                  <th scope="col" style={{border: '1px solid black'}}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "0",
                        margin: "0",
                        // width: "120px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          padding: "0",
                          margin: "0",
                        }}
                      >
                        {changeData2 ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              padding: "0",
                              margin: "0",
                            }}
                          >
                            <div
                              style={{
                                padding: "0",
                              }}
                            >
                              TransferShip
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Boxes
                              <button
                                style={{
                                  border: "none",
                                  zIndex: "1",
                                  cursor: "pointer", // Makes it clear the div is clickable
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: 0,
                                  margin: 0,
                                }}
                                onClick={() => {
                                  setChangeData2((prev) => !prev);
                                }}
                              >
                                🔃
                              </button>
                            </div>
                            <div style={{ padding: "0" }}>
                              {totalData?.totTransferBox}
                            </div>
                          </div>
                        ) : (
                          <div
                            style={
                              {
                                // border: "2px solid green",
                                // width: changeData1 ? "" : "128px",
                              }
                            }
                          >
                            <div>TransferShip</div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Qty
                              <button
                                style={{
                                  border: "none",
                                  zIndex: "1",
                                  cursor: "pointer", // Makes it clear the div is clickable
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: 0,
                                  margin: 0,
                                }}
                                onClick={() => {
                                  setChangeData2((prev) => !prev);
                                }}
                              >
                                🔃
                              </button>
                            </div>
                            <div style={{ padding: "0" }}>
                              {totalData?.totTransferQty}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </th>
                  <th scope="col">
                    <div style={{ padding: 0, margin: 0 }}>Pdf</div>
                  </th>
                  {ctx.userData.etdAccess == 1 && <th scope="col">ETD</th>}
                  {ctx.userData.overshipAccess == 1 && (
                    <th scope="col">Overship%</th>
                  )}
                  <th scope="col" style={{ border: "1px solid black" }}>
                    {isCheckedClosePo ? "Open PO" : "ClosePO"}
                  </th>
                  {isCheckedClosePo && <th>ClosedDate</th>}
                  {ctx.userData.deletePoEnteriesAccess == 1 && (
                    <th>POEnteries</th>
                  )}
                  {/* <th>POSummary</th> */}
                </tr>
              </thead>
              <tbody>
                {currentData && currentData.length >= 1 ? (
                  currentData.map((el, i) => {
                    return (
                      <tr
                        key={el?.PO}
                        className={
                          el?.PO === localStorage.getItem("PO") &&
                          ctx.poData.length !== 0
                            ? "table-danger"
                            : ""
                        }
                      >
                        <td scope="col">{i + 1}</td>
                        <td>{el.STY}</td>
                        <td title="Click to Check PO  Sea, clr, lot, Dim, tot_qty">
                          {isCheckedClosePo ? (
                            el?.PO
                          ) : (
                            <Link to={`/DisplayPurchase?PO=${el?.PO}`}>
                              {el?.PO}
                            </Link>
                          )}
                          {el?.PO === ctx.selectedPo && " ✅"}
                        </td>
                        {
                          // To Display the Po as checked when you have just
                          // checked it in Dsp
                        }
                        <td>{el.FACT}</td>
                        <td>{el.SUP}</td>
                        <td>{el.AGNT}</td>
                        <td>{el.FCUS}</td>
                        <td>{el.totalQuantity}</td>
                        <td>{el.totalPacked}</td>
                        <td>{el.totalBalance * -1}</td>
                        <td>
                          {changeData
                            ? el.totalExFactory
                            : el.totalExFactoryData}
                        </td>
                        <td>
                          {changeData1 ? el.totalShipLoading : el.totalShipData}
                        </td>
                        <td>
                          {changeData2
                            ? el.totalTranferDataBox
                            : el.totalTranferDataQTy}
                        </td>
                        <td className="align-text-top m-0 p-0">
                          <button
                            className="btn btn-none"
                            onClick={() => downloadPdf(el.PO, el.FACT)}
                          >
                            <img
                              width="18"
                              height="18"
                              src="https://img.icons8.com/color/48/000000/export-pdf.png"
                              alt="export-pdf"
                            />
                          </button>
                        </td>
                        {ctx.userData.etdAccess == 1 && (
                          <td>
                            <div
                              key={i}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <DatePicker
                                selected={convertUTCToLocalDate(
                                  selectedDates[i]
                                )}
                                onChange={(date) => handleDateChange(date, i)}
                                dateformat="dd-MM-yyyy"
                                placeholderText="dd-MM-yyyy"
                                className="custom-date-picker"
                                disabled={isDateDisabled[i] || isCheckedClosePo}
                                showMonthDropdown
                                showYearDropdown
                                customInput={
                                  <MaskedTextInput
                                    type="text"
                                    dateformat="dd-mm-yyyy"
                                    mask={[
                                      /\d/,
                                      /\d/,
                                      "-",
                                      /\d/,
                                      /\d/,
                                      "-",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]}
                                  />
                                }
                                autoComplete="off"
                                // utcOffset={0}
                              />
                              {}
                              <button
                                // className="btn btn-success btn-sm ms-1"
                                className={`btn ${
                                  isDateDisabled[i]
                                    ? "btn-secondary"
                                    : "btn-success"
                                } btn-sm ms-1`}
                                style={{ width: "50px" }}
                                // onClick={()=>{console.log(selectedDates[i])}}
                                onClick={(e) =>
                                  handleDateSubmit(e, el, i, selectedDates[i])
                                }
                                disabled={
                                  (el?.PO === localStorage.getItem("PO") &&
                                    ctx.poData.length !== 0) ||
                                  isCheckedClosePo ||
                                  isEtdLoading[i]
                                }
                              >
                                {isDateDisabled[i] ? (
                                  "EDIT"
                                ) : isEtdLoading[i] ? (
                                  <Spinner size="sm" />
                                ) : (
                                  "SAVE"
                                )}
                              </button>
                            </div>
                          </td>
                        )}
                        {ctx.userData.overshipAccess == 1 && (
                          <td>
                            <div className="d-flex justify-content-center align-items-center">
                              <input
                                name="poOvership"
                                placeholder="%"
                                style={{ width: "50px", borderRadius: "4px" }}
                                maxLength={2}
                                // value={overshipData[i]? overshipData[i] : ctx.userData.defaultOvership }
                                value={
                                  // overshipData[i] !== undefined
                                  // ? overshipData[i]
                                  // : ctx.userData.defaultOvership
                                  overshipData[i] !== undefined ||
                                  overshipData[i] !== "" ||
                                  overshipData[i] !== null
                                    ? overshipData[i]
                                    : ctx.userData.defaultOvership
                                }
                                disabled={
                                  isOvershipDisabled[i] || isCheckedClosePo
                                }
                                onChange={(e) => {
                                  const newVal = "";
                                  const newArray = [...overshipData];
                                  newArray[i] = e.target.value
                                    .trim()
                                    .replace(/\D/g, "");
                                  // e.target.value.trim().replace(/\D/g, "")
                                  setOvershipData(newArray);
                                }}
                                autoComplete="off"
                              />
                              <button
                                className={`btn ${
                                  isOvershipDisabled[i]
                                    ? "btn-secondary"
                                    : "btn-success"
                                } btn-sm ms-1`}
                                style={{ width: "50px" }}
                                onClick={(e) =>
                                  handleOvershipSubmit(
                                    e,
                                    el,
                                    i,
                                    overshipData[i]
                                  )
                                }
                                disabled={
                                  (el?.PO === localStorage.getItem("PO") &&
                                    ctx.poData.length !== 0) ||
                                  isCheckedClosePo ||
                                  isOvershipLoading[i]
                                }
                              >
                                {isOvershipDisabled[i] ? (
                                  "EDIT"
                                ) : isOvershipLoading[i] ? (
                                  <Spinner size="sm" />
                                ) : (
                                  "SAVE"
                                )}
                              </button>
                            </div>
                          </td>
                        )}
                        <td>
                          <button
                            className={
                              isCheckedClosePo
                                ? "btn btn-success "
                                : "btn btn-danger"
                            }
                            type="button"
                            onClick={() => {
                              isCheckedClosePo === false
                                ? handleClosePo(el?.PO)
                                : handleOpenPO(el?.PO);
                            }}
                            disabled={
                              el?.PO === localStorage.getItem("PO") &&
                              ctx.poData.length !== 0
                            }
                          >
                            {" "}
                            {isCheckedClosePo ? "OpenPO " : "ClosePO"}
                          </button>
                        </td>
                        {isCheckedClosePo && (
                          <td>{convertTimeToCountry(el.closedDate)}</td>
                        )}
                        {ctx.userData.deletePoEnteriesAccess == 1 && (
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={() =>
                                handlePoEntriesDelete(
                                  el.PO,
                                  el.totalPacked,
                                  el.totalExFactory,
                                  el.totalShipLoading
                                )
                              }
                            >
                              {/* {isPoENteriesDelte ? (
                              <Spinner size="sm" />
                            ) : ( */}
                              Delete
                              {/* )} */}
                            </button>
                          </td>
                        )}
                        {/* <td>
                          <ExcelButton key={el.PO} PO={el.PO} STY= {el.STY}/>
                          <button><i class="bi bi-download"></i></button>
                          <button >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-download"
                            viewBox="0 0 16 16"
                          >
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                          </svg>
                          </button>
                        </td> */}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="100" style={{ textAlign: "center" }}>
                      <h5>No data found</h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="mt-4">
          <Loader />
        </div>
      )}
    </>
  );
};
export default PoSummary;