import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import convertHeader from "../../utility/convertHeader";
import nullify from "../../utility/nullify";
import Weights from "./Weights";
import { Container } from "react-bootstrap";
// import { clear } from "@testing-library/user-event/dist/clear";

const Entries = () => {
  const navigate = useNavigate();
  const { seqId } = useParams();
  const [boxData, setBoxData] = useState([]);
  const [newHeader, setNH] = useState([]);
  const [weightData, setWD] = useState([]);
  // const [isDeleted, setIsDeleted] = useParams(false);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_LINK + "/api/v1/entry/get?id=" + seqId, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setBoxData(res.data.boxData);
        setNH(convertHeader(res.data.boxData[0].purchaseOrder.SIZE));
        setWD(res.data.entry);
        // console.log(res.data);
      })
      .catch((err) => {
        alert("No Data found");
        navigate(-1);
      });
  }, []);

  return (
    <>
      <Header />
      <button
        type="button"
        className="btn btn-secondary mt-2 ms-5"
        onClick={() => navigate("/boxDetails")}
      >
        Back
      </button>
      <h1 className="text-center mb-4 text-danger">Box Details Display</h1>
      <div className=" mx-4 mt-2">
        <Weights weightData={weightData} setWeightData={setWD} />
        <br />
        <table className="table table-bordered table-hover table-sm ">
          <thead>
            <tr className="table-warning">
              <th>PO</th>
              <th>SEA</th>
              <th>STY</th>
              <th>LOT</th>
              <th>CLR</th>
              <th>DIM</th>
              <th>TOT_QTY</th>
              <th>{newHeader.length !== 0 ? newHeader[0] : "SZ01"}</th>
              <th>{newHeader.length !== 0 ? newHeader[1] : "SZ02"}</th>
              <th>{newHeader.length !== 0 ? newHeader[2] : "SZ03"}</th>
              <th>{newHeader.length !== 0 ? newHeader[3] : "SZ04"}</th>
              <th>{newHeader.length !== 0 ? newHeader[4] : "SZ05"}</th>
              <th>{newHeader.length !== 0 ? newHeader[5] : "SZ06"}</th>
              <th>{newHeader.length !== 0 ? newHeader[6] : "SZ07"}</th>
              <th>{newHeader.length !== 0 ? newHeader[7] : "SZ08"}</th>
              <th>{newHeader.length !== 0 ? newHeader[8] : "SZ09"}</th>
              <th>{newHeader.length !== 0 ? newHeader[9] : "SZ10"}</th>
              <th>{newHeader.length !== 0 ? newHeader[10] : "SZ11"}</th>
              <th>{newHeader.length !== 0 ? newHeader[11] : "SZ12"}</th>
            </tr>
          </thead>

          {boxData.map((box, index) => {
            return (
              <tbody>
                <tr className="table-active" key={index}>
                  <td>{box.purchaseOrder.PO}</td>
                  <td>{box.purchaseOrder.SEA}</td>
                  <td>{box.purchaseOrder.STY}</td>
                  <td>{box.purchaseOrder.LOT}</td>
                  <td>{box.purchaseOrder.CLR}</td>
                  <td>{box.purchaseOrder.DIM}</td>
                  <td>
                    {parseInt(box.SZ01 || 0) +
                      parseInt(box.SZ02 || 0) +
                      parseInt(box.SZ03 || 0) +
                      parseInt(box.SZ04 || 0) +
                      parseInt(box.SZ05 || 0) +
                      parseInt(box.SZ06 || 0) +
                      parseInt(box.SZ07 || 0) +
                      parseInt(box.SZ08 || 0) +
                      parseInt(box.SZ09 || 0) +
                      parseInt(box.SZ10 || 0) +
                      parseInt(box.SZ11 || 0) +
                      parseInt(box.SZ12 || 0)}
                  </td>
                  <td>
                    <input
                      type={"text"}
                      value={nullify(box.SZ01)}
                      //   onChange={handleUserInput}
                      name="SZ01"
                      disabled
                      autoComplete="off"
                    />
                  </td>
                  <td>
                    <input
                      type={"text"}
                      value={nullify(box.SZ02)}
                      //   onChange={handleUserInput}
                      name="SZ02"
                      disabled
                      autoComplete="off"
                    />
                  </td>
                  <td>
                    <input
                      type={"text"}
                      value={nullify(box.SZ03)}
                      //   onChange={handleUserInput}
                      name="SZ03"
                      disabled
                      autoComplete="off"
                    />
                  </td>
                  <td>
                    <input
                      type={"text"}
                      value={nullify(box.SZ04)}
                      //   onChange={handleUserInput}
                      name="SZ04"
                      disabled
                      autoComplete="off"
                    />
                  </td>
                  <td>
                    <input
                      type={"text"}
                      value={nullify(box.SZ05)}
                      //   onChange={handleUserInput}
                      name="SZ05"
                      disabled
                      autoComplete="off"
                    />
                  </td>
                  <td>
                    <input
                      type={"text"}
                      value={nullify(box.SZ06)}
                      //   onChange={handleUserInput}
                      name="SZ06"
                      disabled
                      autoComplete="off"
                    />
                  </td>
                  <td>
                    <input
                      type={"text"}
                      value={nullify(box.SZ07)}
                      //   onChange={handleUserInput}
                      name="SZ07"
                      disabled
                      autoComplete="off"
                    />
                  </td>
                  <td>
                    <input
                      type={"text"}
                      value={nullify(box.SZ08)}
                      //   onChange={handleUserInput}
                      name="SZ08"
                      disabled
                      autoComplete="off"
                    />
                  </td>
                  <td>
                    <input
                      type={"text"}
                      value={nullify(box.SZ09)}
                      //   onChange={handleUserInput}
                      name="SZ09"
                      disabled
                      autoComplete="off"
                    />
                  </td>
                  <td>
                    <input
                      type={"text"}
                      value={nullify(box.SZ10)}
                      //   onChange={handleUserInput}
                      name="SZ10"
                      disabled
                      autoComplete="off"
                    />
                  </td>
                  <td>
                    <input
                      type={"text"}
                      value={nullify(box.SZ11)}
                      //   onChange={handleUserInput}
                      name="SZ11"
                      disabled
                      autoComplete="off"
                    />
                  </td>
                  <td>
                    <input
                      type={"text"}
                      value={nullify(box.SZ12)}
                      //   onChange={handleUserInput}
                      name="SZ12"
                      disabled
                      autoComplete="off"
                    />
                  </td>
                </tr>
                <tr style={{ border: "none", height: "30px" }}>
                  <td style={{ border: "none" }}></td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default Entries;
