module.exports = function checkRatio(values, ratios) {
    if (values.length !== ratios.length) {
      // If the number of values doesn't match the number of ratios, return false
      return false;
    }
  
    const total = ratios.reduce((sum, ratio) => sum + ratio, 0);
    const targetValues = ratios.map(ratio => ratio * (values.reduce((sum, value) => sum + value, 0)) / total);
  
    for (let i = 0; i < values.length; i++) {
        if (Math.abs(values[i] - Math.round(targetValues[i])) > Number.EPSILON || ratios[i]>values[i]) {
        // If the value doesn't match the expected target value, return false
        return false;
      }
    }
  
    return true;
  }