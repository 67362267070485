module.exports = function formatDate(str) {
  if (str == 0) {
    return null;
  } else {
    let yy = str.slice(0, 4);
    let mm = str.slice(4, 6);
    let dd = str.slice(6, 8);
    let date = dd + "/" + mm + "/" + yy;
    return date;
  }
};
