import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSocketContext } from "../context/socketContext";

function MaintenanceModal({ open }) {
  const [isModalOpen, setIsModalOpen] = useState(open);
  const { emit, on } = useSocketContext();
  useEffect(() => {
    console.log("hit")
    on("maintenance", (time) => {
      console.log("hit 2", Date.now() < time)
      if (Date.now() < time) {
        setIsModalOpen(true);
        console.log("hit 2")
      }
    });
  }, []);

  return (
    <Modal
      show={isModalOpen}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Scheduled Maintenance
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Centered Modal</h4>
        <p>
          Our services will be undergoing scheduled maintenance on
          <strong> August 30th, 2024 from 2:00 AM to 4:00 AM UTC</strong>.
          Please plan accordingly, as services may be unavailable during this
          time.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setIsModalOpen(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
    // <div className="App">
    //   <Modal
    //     isOpen={isModalOpen}
    //     onClose={() => setIsModalOpen(false)}
    //     isClosable={isClosable} // Set to false to make the modal unclosable
    //   >
    //     <h2>Scheduled Maintenance</h2>
    //     <p>
    //       Our services will be undergoing scheduled maintenance on
    //       <strong> August 30th, 2024 from 2:00 AM to 4:00 AM UTC</strong>.
    //       Please plan accordingly, as services may be unavailable during this time.
    //     </p>
    //     <p>We apologize for any inconvenience and appreciate your understanding.</p>
    //   </Modal>
    // </div>
  );
}

export default MaintenanceModal;
