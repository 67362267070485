import React from "react";
import "./GraphLoader.css";

const GraphLoader = () => {
  return (
    <div className="spinner_main">
      <div className="dot-spinner">
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
      </div>
      {/* <p className="loading-text">Data is loading, please wait...</p>{" "}
      Add a message below the spinner
      <p className="loading-remaining-text">Some data will remain.</p>{" "}
      Add message about remaining data */}
    </div>
  );
};

export default GraphLoader;
