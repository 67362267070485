import Spinner from "react-bootstrap/Spinner";
import "./Loader.css";

function Loader() {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
      <h5>Please wait while your data is being loaded....</h5>
    </div>
  );
}

export default Loader;
