const moment = require("moment-timezone");

module.exports = function convertTimeToCountry(utc) {
  // Parse the UTC datetime string
  const utcMoment = moment.utc(utc);

  // Convert to the local timezone
  const localMoment = utcMoment.tz(localStorage.getItem("timezone"));

  // Format without timezone information
  const formattedString = localMoment.format(" ddd DD-MM-YYYY HH:mm:ss", 12);

  // console.log("timeeee", formattedString);
  //   return moment(utc).tz(localStorage.getItem("timezone")).toString();
  return formattedString;
};
