const sortByColumn = (data, columnName, check) => {
    return data.slice().sort((a, b) => {
      const valA = a[columnName];
      const valB = b[columnName];
      
      if (
        typeof valA === "number" &&
        typeof valB === "number"
      ) {
        return check === "⬆"
          ? valB - valA
          : valA - valB;
      } else if (
        typeof valA === "string" &&
        typeof valB === "string"
      ) {
        const compareResult = valA.localeCompare(valB);
        return check === "⬇" ? -compareResult : compareResult;
      } else return 0;
    });
  };

export default sortByColumn;


// module.export = function compareValues(a, b, order) {
//   const extractValue = (item) => {
//     const value = item[sortedField];
//     return typeof value === "number" ? value : value.toLowerCase();
//   };

//   const aValue = extractValue(a);
//   const bValue = extractValue(b);

//   if (order === "asc") {
//     return aValue > bValue ? 1 : -1;
//   } else {
//     return bValue > aValue ? 1 : -1;
//   }
// };

// const sortData = (data, field, sortOrder) => {
//   return [...data].sort((a, b) => compareValues(a, b, sortOrder));
// };

// export const sortData = (data, sortConfig) => {
//   const sortableData = [...data];
//   if (sortConfig.key !== null) {
//     sortableData.sort((a, b) => {
//       if (a[sortConfig.key] < b[sortConfig.key]) {
//         return sortConfig.direction === "asc" ? -1 : 1;
//       }
//       if (a[sortConfig.key] > b[sortConfig.key]) {
//         return sortConfig.direction === "asc" ? 1 : -1;
//       }
//       return 0;
//     });
//   }
//   return sortableData;
// };
