import React, { createContext, useState } from "react";

const OrderContext2 = createContext({
  checkUpdateButton: false,
  setCheckUpdateButton: () => {},
});

export const ContextProvider2 = (props) => {
  const [checkUpdateButton, setCheckUpdateButton] = useState(false);

  return (
    <OrderContext2.Provider
      value={{
        checkUpdateButton,
        setCheckUpdateButton, // Pass the custom handler
      }}
    >
      {props.children}
    </OrderContext2.Provider>
  );
};

export default OrderContext2;
