import React, { useContext, useEffect, useState } from "react";

import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";

import axios from "axios";
import OrderContext from "../../../context/context";
import { toast } from "react-hot-toast";
import { Navigate, useNavigate } from "react-router-dom";
import ErrosToast from "../../../components/ErrosToast";

const Weights = ({ weightData, setWeightData, totalQuantity }) => {
  const ctx = useContext(OrderContext);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  //  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  // const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const [boxType, setBoxType] = useState([]); //for boxtype dropdown
  //const [selectedOption, SetSelectedOption] = useState('select')
  const [dspPodata, setDspPoData] = useState({}); // for collecting selected po from dsp

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_LINK + "/api/v1/admin/getAllBoxMasters"

        // {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("token")}`,
        //   },
        // }
      )
      .then((res) => {
        //console.log('result hai kya',res);
        setBoxType(res.data.boxMasters);
      })
      .catch((err) => {
        // console.log(err)
      });
  }, []);

  // console.log("boxData ", ctx.boxData[0]);

  // useEffect(()=> {
  //   const body = {
  //     PO: ctx.boxData[0]?.purchaseOrder.PO,
  //     SEA: ctx.boxData[0]?.purchaseOrder.SEA || "",
  //     STY: ctx.boxData[0]?.purchaseOrder.STY || "",
  //     LOT: ctx.boxData[0]?.purchaseOrder.LOT || "",
  //     CLR: ctx.boxData[0]?.purchaseOrder.CLR || "",
  //     DIM: ctx.boxData[0]?.purchaseOrder.DIM || "",
  //     FACT: ctx.boxData[0]?.purchaseOrder.FACT || "",
  //     SUP: ctx.boxData[0]?.purchaseOrder.SUP || "",
  //     AGNT: ctx.boxData[0]?.purchaseOrder.AGNT || "",
  //   };
  //   axios
  //   .post("/po/getFilteredPurchaseOrders", {
  //     ...body,
  //     open: true,
  //     processed: true,
  //     pageNo: 0,
  //     docNo: 50,
  //     order: [
  //       ["FACT", "ASC"],
  //       ["SUP", "ASC"],
  //       ["AGNT", "ASC"],
  //       ["PO", "ASC"],
  //       ["FCUS", "ASC"],
  //     ],
  //   }
  //   )
  //   .then((res) => {
  //     console.log('res', res.data.poData)
  //     setDspPoData(res.data.poData[0].packedQuantity.TOT_QTY)
  //   })
  // }, [ctx.boxData[0]])

  // console.log('box data', ctx.boxData[0])
  // console.log('dsp data from here',dspPodata)
  // console.log('weight data', weightData)

  const handleUserInput = (e) => {
    e.preventDefault();
    setWeightData({ ...weightData, [e.target.name]: e.target.value });

    // console.log("new weightData", weightData);
  };

  // console.log('from weight')

  const handleUpdate = async () => {
    const total_quantity = ctx.boxData.map((boxData) => {
      // Parse each size value with parseInt(), or treat it as 0 if it's blank or missing
      const parsedValues = [
        parseInt(boxData.SZ01) || 0,
        parseInt(boxData.SZ02) || 0,
        parseInt(boxData.SZ03) || 0,
        parseInt(boxData.SZ04) || 0,
        parseInt(boxData.SZ05) || 0,
        parseInt(boxData.SZ06) || 0,
        parseInt(boxData.SZ07) || 0,
        parseInt(boxData.SZ08) || 0,
        parseInt(boxData.SZ09) || 0,
        parseInt(boxData.SZ10) || 0,
        parseInt(boxData.SZ11) || 0,
        parseInt(boxData.SZ12) || 0,
      ];

      // Sum up all parsed values
      return parsedValues.reduce((acc, currentValue) => acc + currentValue, 0);
    });

    const check_zero_or_not = total_quantity.includes(0);
    if (check_zero_or_not) {
      toast.remove();
      ErrosToast(
        "Please check tot_QTy, Lot ratio is not correct , it will set to 0"
      );
      return;
    }

    let ind = 0;

    ctx.boxData.forEach((bD, index) => {
      const totQty =
        bD.SZ01 * 1 +
        bD.SZ02 * 1 +
        bD.SZ03 * 1 +
        bD.SZ04 * 1 +
        bD.SZ05 * 1 +
        bD.SZ06 * 1 +
        bD.SZ07 * 1 +
        bD.SZ08 * 1 +
        bD.SZ09 * 1 +
        bD.SZ10 * 1 +
        bD.SZ11 * 1 +
        bD.SZ12 * 1;

      // console.log(
      //   weightData,
      //   totQty,
      //   weightData.noOfBoxes,
      //   bD.purchaseOrder.TOT_QTY * 1 +
      //     bD.purchaseOrder.TOT_QTY *
      //       (ctx.overshipValue > 0
      //         ? ctx.overshipValue / 100
      //         : ctx.userData.defaultOvership / 100),
      //   bD.purchaseOrder.TOT_QTY * 1
      // );
      if (
        totQty * weightData.noOfBoxes >
        bD.purchaseOrder.TOT_QTY * 1 +
          bD.purchaseOrder.TOT_QTY *
            (ctx.overshipValue > 0
              ? ctx.overshipValue / 100
              : ctx.userData.defaultOvership / 100)
      ) {
        if (
          // !(ctx.poData[key].TOT_QTY * 1 === 0 && ctx.poData[key].LOT === "000") //it can be used in future as of now we want all 000 lot  should proceed for more  for all negative qty
          !(
            bD.purchaseOrder.LOT === "000" &&
            (ctx.overshipValue > 0
              ? ctx.overshipValue
              : ctx.userData.defaultOvership) > 0
          )
        )
          return (ind = index + 1);
      }
    });
    if (ind) {
      return alert(
        `Position: ${ind} can't submit more than ${
          ctx.overshipValue > 0
            ? ctx.overshipValue
            : ctx.userData.defaultOvership
        }% of total qty`
      );
    }
    if (window.confirm("Are you sure?")) {
      setIsLoading(true);
      await axios
        .post(
          process.env.REACT_APP_API_LINK + "/api/v1/entry/update",
          {
            boxData: ctx.boxData,
            weightData,
            seqId: weightData.id,
            p: "h",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(async () => {
          if (ctx.poData.length != 0) {
            await axios
              .post(
                process.env.REACT_APP_API_LINK +
                  "/api/v1/po/getLockedPurchaseOrders",
                {
                  POIDs: ctx.selectedCheckboxes,
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then((res) => {
                ctx.setPoData(res.data.poData);
                // toast.remove();
                // toast.success("Success");
              });

            await axios
              .post("/po/getFilteredPurchaseOrders", {
                ...ctx.id,
                open: ctx.openCheck,
                processed: ctx.procesCheck,
                pageNo: 0,
                docNo: 30,
                order: [
                  ["FACT", "ASC"],
                  ["SUP", "ASC"],
                  ["AGNT", "ASC"],
                  ["PO", "ASC"],
                  ["ETA", "ASC"],
                ],
              })
              .then((res) => {
                ctx.setPoDataDSP(res.data.poData);
                // console.log("resssaaaa", res.data.poData);
                toast.success(
                  "Entry updated Successfully, Reprint the barcode pdf "
                );
              });
            setIsLoading(false);

            navigate("/boxDetails");
          } else {
            await axios
              .post("/po/getFilteredPurchaseOrders", {
                ...ctx.id,
                open: ctx.openCheck,
                processed: ctx.procesCheck,
                pageNo: 0,
                docNo: 30,
                order: [
                  ["FACT", "ASC"],
                  ["SUP", "ASC"],
                  ["AGNT", "ASC"],
                  ["PO", "ASC"],
                  ["ETA", "ASC"],
                ],
              })
              .then((res) => {
                ctx.setPoDataDSP(res.data.poData);
                // console.log("resssaaaa", res.data.poData);
                toast.success(
                  "Entry updated Successfully, Reprint the barcode pdf "
                );
              });
            setIsLoading(false);
            navigate("/boxDetails");
          }
        })

        .catch((error) => {
          setIsLoading(false);
          // console.log(error);
        });
    } else {
      setIsLoading(false);
      ErrosToast("cancelled!");
    }
  };

  const handleCancel = () => {
    navigate("/boxDetails");
  };

  const handleBoxTypeChange = (e) => {
    const boxtypeValue = e.target.value;
    if (boxtypeValue == "") {
      setWeightData({
        ...weightData,
        BoxType: "",
      });
    }
    //console.log('prev value',boxtypeValue)
    else if (e.target.value !== null && boxType[boxtypeValue]) {
      setWeightData({
        ...weightData,
        // NetWt: boxType[e.target.value].NetWt,
        // GrossWt: boxType[e.target.value].GrossWt,
        Length: boxType[e.target.value].Length,
        Width: boxType[e.target.value].Width,
        Height: boxType[e.target.value].Height,
        BoxType: boxType[e.target.value].type,
      });
    }
    //console.log(boxtypeValue)
  };

  //console.log("weifht daa", weightData);

  return (
    <>
      <div className="mx-4 mt-2">
        <Form>
          <Row>
            <Col>
              <Form.Group controlId="input1">
                <Form.Label className="bold-label">SequenceId:</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={7}
                  name="SequenceId"
                  autoComplete="off"
                  value={weightData.id}
                  placeholder="SequenceId"
                  disabled
                  // onChange={handleUserInput}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="input7">
                <Form.Label className="bold-label">Boxes:</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={4}
                  name="noOfBoxes"
                  autoComplete="off"
                  value={weightData.noOfBoxes}
                  placeholder="Boxes"
                  disabled
                  // onChange={handleUserInput}
                  className="custom-input"
                  // disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Label>BoxType:</Form.Label>
              <Form.Select
                id="dropdown"
                size="md"
                style={{ width: "225px", border: "2px solid #000" }}
                onChange={handleBoxTypeChange}
                value={
                  weightData.BoxType
                    ? boxType.findIndex((bt) => bt.type === weightData.BoxType)
                    : ""
                }
                autoComplete="off"
              >
                <option key={"select"} value={""}>
                  select
                </option>

                {boxType &&
                  boxType.map((bt, i) => {
                    return (
                      <option key={bt.id} value={i}>
                        {bt.type}
                      </option>
                    );
                  })}
              </Form.Select>
            </Col>

            <Col>
              <Form.Group controlId="input5">
                <Form.Label className="bold-label">Length(cm)</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={7}
                  name="Length"
                  autoComplete="off"
                  value={weightData.Length}
                  placeholder="Length"
                  onChange={handleUserInput}
                  className="custom-input"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="input6">
                <Form.Label className="bold-label">Width(cm)</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={7}
                  name="Width"
                  autoComplete="off"
                  value={weightData.Width}
                  placeholder="Width"
                  onChange={handleUserInput}
                  className="custom-input"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="input7">
                <Form.Label className="bold-label">Height(cm)</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={7}
                  name="Height"
                  autoComplete="off"
                  value={weightData.Height}
                  placeholder="Height"
                  onChange={handleUserInput}
                  className="custom-input"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="input3">
                <Form.Label className="bold-label">NetWt(kg/gm)</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={7}
                  name="NetWt"
                  autoComplete="off"
                  value={weightData.NetWt}
                  placeholder="NetWt"
                  onChange={handleUserInput}
                  className="custom-input"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="input4">
                <Form.Label className="bold-label">GrossWt(kg/gm)</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={7}
                  name="GrossWt"
                  autoComplete="off"
                  value={weightData.GrossWt}
                  placeholder="GrossWt"
                  onChange={handleUserInput}
                  className="custom-input"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="input2">
                <Form.Label className="bold-label">Box Group</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={20}
                  name="BoxGroup"
                  autoComplete="off"
                  value={weightData.BoxGroup}
                  placeholder="BoxGroup"
                  onChange={handleUserInput}
                  className="custom-input"
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "20px",
              }}
            >
              <div>
                <Col>
                  <Form.Label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp;
                  </Form.Label>
                  {/* <Button variant="primary" onClick={handleUpdate} className="btn w-100 p-3 h-50 d-inline-block"> */}
                  <Button
                    variant="primary"
                    onClick={handleUpdate}
                    className="btn ms-4"
                  >
                    <div className="">
                      {isLoading ? <Spinner size="sm" /> : "Update"}
                    </div>
                  </Button>
                </Col>
              </div>
              <div>
                <Col>
                  <Form.Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Form.Label>
                  {/* <Button variant="primary" onClick={handleUpdate} className="btn w-100 p-3 h-50 d-inline-block"> */}
                  <Button
                    variant="danger"
                    onClick={handleCancel}
                    className="btn me-4"
                  >
                    <div className="">Cancel</div>
                  </Button>
                </Col>
              </div>
            </div>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default Weights;
