import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import "./PoFileLoadingStatus.css";

function CustomSpinner() {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  
function PoFileLoadingStatus() {
    const [loading, setLoading] = useState(true)
    const [remaining_file_count, setRemainingFilesCount] = useState(0);
    useEffect(()=>{
        async function fetch_data(){
            await fetchCurrentFileLoadingStatus();
        }
        fetch_data();
    }, [])

    const fetchCurrentFileLoadingStatus = async () =>{
        setLoading(true)
        const res = await axios.get("/admin/getCurrentPoFileLoadingStatus");
        // console.log(res)
        const file_count = res.data.remaining_files;
        setRemainingFilesCount(file_count)
        setLoading(false)
    }

    const refreshHandler = async ()=>{
        await fetchCurrentFileLoadingStatus();
    }

  return (
    <div id="main" style={{ background: "white" }}>
      <Navbar className="bg-body-tertiary">
        <Container id="container">
          <div id="container_">
            <Navbar.Brand>File loading status</Navbar.Brand>
            <Navbar.Brand id="Spinner">
              {loading ? (
                <CustomSpinner />
              ) : (
                remaining_file_count + " remaining"
              )}
            </Navbar.Brand>
            <Navbar.Toggle />
          </div>
          <div className="justify-content-end" id="btn_both">
            <Button variant="success" onClick={refreshHandler}>
              Refresh
            </Button>
            <Button variant="primary">History</Button>
          </div>
        </Container>
      </Navbar>
    </div>
  );
}

export default PoFileLoadingStatus;