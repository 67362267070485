import axios, { Axios } from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import Context from "../../context/context";
import Loader from "../../components/Loader";
import moment from "moment/moment";
import convertTimeToCountry from "../../utility/convertTimeToCountry";
import toast from "react-hot-toast";
import Headroom from "react-headroom";
import Header from "../../components/header";
import ReactPaginate from "react-paginate";
import { useCallback } from "react";
import { debounce } from "underscore";
import ErrosToast from "../../components/ErrosToast";

// import { useContext } from "react";
// import { Tooltip } from "react-tooltip";
import {
  Tooltip,
  TooltipTrigger,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
} from "@radix-ui/react-tooltip";

const Table = () => {
  const ctx = useContext(Context);
  const btnlogic = ctx.userData.username;

  const {
    userData,
    boxDetailSent,
    setboxDetailSent,
    boxDetailUnsent,
    setboxDetailUnsent,
  } = useContext(Context);

  const navigate = useNavigate();
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [BoxGroup, setBoxGroup] = useState(""); // state for holding input of boxgroup  field

  const [searchPo, setSearchPo] = useState(""); // state for holding input of PO FIELD
  const [searchStyle, setSearchStyle] = useState(""); // state for holding input of Style field
  const [searchUser, setSearchUser] = useState(""); // state for holding input of user field

  const [isLoading, setIsLoading] = useState(true);
  //const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [initialFetchDone, setInitialFetchDone] = useState(false);
  // const [isStartPagination, setIsStartPagination] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const getEntries = async (
    BoxGroup = "",
    po = "",
    sty = "",
    username = "",
    pageNo,
    limit
  ) => {
    // console.log("BoxGroup", BoxGroup);
    // console.log("po", po);
    // console.log("sty", sty);
    // console.log("username", username);
    setIsLoading(true);
    try {
      const res = await axios.get(
        `/entry/getAll?pageNo=${pageNo}&docNo=${limit}&BoxGroup=${BoxGroup}&po=${po}&sty=${sty}&
username=${username}&sent=${boxDetailSent.toString()}&unsent=${boxDetailUnsent.toString()}`
      );
      setIsLoading(false);
      // console.log("resss", res);
      setFilteredEntries(res.data.entries);
      // ctx.setPageCountBoxEntries(Math.ceil(res.data.pageCount));

      setPage(pageNo);
      if (res.data.entries.length < limit) {
        setHasMore(false);
      }
      setInitialFetchDone(true);
      // console.log('page length', res.data.entries.length)
      // ctx.setCurrentPageBoxEntries(0);
      // console.log("resuuuu", res.data.entries);
      // handleSentUnsent(sent, unsent);
    } catch (err) {
      // setIsLoading(false);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // getEntries(BoxGroup, searchPo, searchStyle, searchUser);
    // console.log("calling or not");
    if (!initialFetchDone) {
      getEntries(BoxGroup, searchPo, searchStyle, searchUser, 0, 30);
      // setInitialFetchDone(true); // Set initialFetchDone to true after initial fetch
      return;
    }

    const tableContainer = document.querySelector(".table-container");
    if (!tableContainer) return;

    const handleScroll = () => {
      const table = tableContainer.querySelector(".table");

      if (!table) return;

      const tableBody = table.querySelector("tbody");

      // Add this check
      if (!tableBody) return;

      const lastRow = tableBody.querySelector("tr:last-child");

      if (lastRow) {
        const lastRowOffset = lastRow.offsetTop + lastRow.clientHeight;
        const containerOffset =
          tableContainer.scrollTop + tableContainer.clientHeight;
        const bottomOffset = 20;

        if (
          containerOffset > lastRowOffset - bottomOffset &&
          hasMore &&
          !isLoading
        ) {
          getEntries(
            BoxGroup,
            searchPo,
            searchStyle,
            searchUser,
            0,
            30 * ((filteredEntries.length + 30) / 30)
          );
        }
      }
    };

    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [boxDetailSent, boxDetailUnsent, page, isLoading]);

  //console.log("page count", ctx.pageCountBoxEntries);
  const handlePageClick = (e) => {
    // for pagination handle function
    setIsLoading(true);
    axios
      .get(
        `/entry/getAll?pageNo=${e.selected
        }&docNo=${30}&BoxGroup=${BoxGroup}&sent=${boxDetailSent.toString()}&unsent=${boxDetailUnsent.toString()}`
      )
      .then((res) => {
        console.log("after handle page", res);
        ctx.setCurrentPageBoxEntries(e.selected);

        setFilteredEntries(res.data.entries);
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle error if the axios request fails
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };
  //console.log(filteredEntries);

  // const handleFilter = (shipNo)=> {
  //   axios.get(`/entry/filter?shipNo=${shipNo}&sent=`)
  // }

  // useEffect(() => {
  //   getEntries();
  // }, []);

  //console.log('get entries', getEntries(shipNo,searchPo));

  const HandleModification = (seqId) => {
    if (userData.admin === 1) {
      toast.remove();
      ErrosToast("Admin can not Modify Entry");
      return;
    }

    navigate(`/boxDetails/modify/${seqId}`);
  };

  const HandleSpecificModification = (seqId) => {
    if (userData.admin === 1) {
      toast.remove();
      ErrosToast("Admin can not Modify Entry");
      return;
    }

    navigate(`/boxDetails/specific/${seqId}`);
  };

  const Handlereset = () => {
    // setIsLoading(false);
    // setTimeout(()=> {
    //   setIsStartPagination((prev)=> !prev)
    //   // setInitialFetchDone(false)
    // })
    setInitialFetchDone(false);
    setHasMore(true);
    getEntries("", "", "", "", 0, 30);

    setBoxGroup("");
    setSearchPo("");
    setSearchStyle("");
    setSearchUser("");
  };

  const HandleDelete = async (seqId) => {
    // Set isLoadingButton to true for the specific entry
    //console.log('userData', userData)

    if (userData.admin === 1) {
      toast.remove();
      ErrosToast("Admin can not delete entry");
      return;
    } else if (
      window.confirm(
        `Do you want to delete the data of ${seqId}?\nBoxRange will move backwards  with the deleted number of .`
      )
    ) {
      // setIsLoading(false)
      // setInitialFetchDone(false)
      try {
        setFilteredEntries((prevEntries) =>
          prevEntries.map((entry) =>
            entry.id === seqId ? { ...entry, isLoadingButton: true } : entry
          )
        );
        await axios
          .get(
            process.env.REACT_APP_API_LINK + "/api/v1/entry/delete?id=" + seqId,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(async () => {
            toast.success(`${seqId}: data deleted successfully.`);

            // Remove the deleted entry from the state
            // setFilteredEntries((prevEntries) =>
            //   prevEntries.filter((entry) => entry.id !== seqId)
            // );

            getEntries(BoxGroup, searchPo, searchStyle, searchUser, 0, 30);
            // setIsStartPagination((prev)=> !prev)
            if (localStorage.getItem("PO") === ctx.selectedCheckboxes) {
              await axios
                .post(
                  process.env.REACT_APP_API_LINK +
                  "/api/v1/po/getLockedPurchaseOrders",
                  {
                    POIDs: ctx.selectedCheckboxes,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                )
                .then((res) => {
                  ctx.setPoData(res.data.poData);
                  ctx.setLastTo(res.data.lastValueOfTo);
                });

              await axios
                .post("/po/getFilteredPurchaseOrders", {
                  ...ctx.id,
                  open: ctx.openCheck,
                  processed: ctx.procesCheck,
                  pageNo: 0,
                  docNo: 30,
                  order: [
                    ["FACT", "ASC"],
                    ["SUP", "ASC"],
                    ["AGNT", "ASC"],
                    ["PO", "ASC"],
                    ["ETA", "ASC"],
                  ],
                })
                .then((res) => {
                  ctx.setPoDataDSP(res.data.poData);
                  console.log("resssaaaa", res.data.poData);
                });

              setIsLoading(false);
              navigate("/boxDetails");
            } else {
              await axios
                .post("/po/getFilteredPurchaseOrders", {
                  ...ctx.id,
                  open: ctx.openCheck,
                  processed: ctx.procesCheck,
                  pageNo: 0,
                  docNo: 30,
                  order: [
                    ["FACT", "ASC"],
                    ["SUP", "ASC"],
                    ["AGNT", "ASC"],
                    ["PO", "ASC"],
                    ["ETA", "ASC"],
                  ],
                })
                .then((res) => {
                  ctx.setPoDataDSP(res.data.poData);
                  console.log("resssaaaa", res.data.poData);
                });

              setIsLoading(false);
              navigate("/boxDetails");
            }
          });
      } catch (error) {
        console.error(error);
        // Handle error as needed
      } finally {
        // Set isLoadingButton to false after the delete operation completes (whether it succeeds or fails)
        setFilteredEntries((prevEntries) =>
          prevEntries.map((entry) => ({ ...entry, isLoadingButton: false }))
        );
      }
    } else {
      // If the user cancels the delete operation, set isLoadingButton to false
      setFilteredEntries((prevEntries) =>
        prevEntries.map((entry) => ({ ...entry, isLoadingButton: false }))
      );
    }
  };

  // console.log("filtered one", filteredEntries);
  // console.log('search box', searchPo)
  // console.log('boxDetails sent', boxDetailSent);
  // console.log('unsent', boxDetailUnsent)
  const handleFilter = (e, BoxGroup, searchPo, searchStyle, searchUser) => {
    e.preventDefault();
    const encodedBoxGroup = encodeURIComponent(BoxGroup);
    const encodedPo = encodeURIComponent(searchPo);
    const encodedStyle = encodeURIComponent(searchStyle);
    const encodedUser = encodeURIComponent(searchUser);

    getEntries(encodedBoxGroup, encodedPo, encodedStyle, encodedUser, 0, 30);
  };

  const OnChangeHandleFilter = useCallback(
    // for filter by every letter
    debounce(
      (e, BoxGroup, searchPo, searchStyle, searchUser) =>
        handleFilter(e, BoxGroup, searchPo, searchStyle, searchUser),
      1000,
      false
    ),
    []
  );

  return (
    <>
      <div className="m-0 p-0">
        <div className="">
          <div className="fixed-top">
            <Header />
            <div style={{ background: "#ffff", padding: ".5% 0% 0% 0%" }}>
              <div className="mx-4" style={{ alignItems: "center" }}>
                <Form
                  onSubmit={(e) =>
                    handleFilter(
                      (e, BoxGroup, searchPo, searchStyle, searchUser)
                    )
                  }
                >
                  <Row
                    style={{
                      background: "#ffff",
                      padding: "0% 0% 1.5% 0%",
                      width: "101%",
                    }}
                  >
                    <Col lg={2}>
                      <Form.Group>
                        <Form.Label className="fs-6">Box Group</Form.Label>
                        <Form.Control
                          size="sm"
                          type="Search"
                          id="input-search"
                          value={BoxGroup}
                          onChange={(e) => {
                            setBoxGroup(
                              e.target.value.toUpperCase().replace(/%/g, "")
                            );
                            OnChangeHandleFilter(
                              e,
                              e.target.value.toUpperCase(),
                              searchPo,
                              searchStyle,
                              searchUser
                            );
                          }}
                          placeholder="#20 Bytes"
                          maxLength={20}
                          style={{ border: "2px solid #000 " }}
                          autoComplete="off"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={2}>
                      <Form.Group>
                        <Form.Label className="fs-6">PO#</Form.Label>
                        <Form.Control
                          size="sm"
                          type="Search"
                          id="input-search"
                          placeholder="PO"
                          maxLength={6}
                          style={{ border: "2px solid #000 " }}
                          value={searchPo}
                          onChange={(e) => {
                            setSearchPo(e.target.value.replace(/\D/g, ""));
                            OnChangeHandleFilter(
                              e,
                              BoxGroup,
                              e.target.value.replace(/\D/g, "").toUpperCase(),
                              searchStyle,
                              searchUser
                            );
                          }}
                          autoComplete="off"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={2}>
                      <Form.Group>
                        <Form.Label className="fs-6">Style</Form.Label>
                        <Form.Control
                          size="sm"
                          type="Search"
                          id="input-search"
                          placeholder="Style"
                          maxLength={8}
                          style={{ border: "2px solid #000 " }}
                          value={searchStyle}
                          onChange={(e) => {
                            setSearchStyle(e.target.value.replace(/%/g, ""));
                            OnChangeHandleFilter(
                              e,
                              BoxGroup,
                              searchPo,
                              e.target.value,
                              searchUser
                            );
                          }}
                          autoComplete="off"
                        />
                      </Form.Group>
                    </Col>
                    {!!userData.admin && (
                      <Col lg={2}>
                        <Form.Group>
                          <Form.Label className="fs-6">User</Form.Label>
                          <Form.Control
                            size="sm"
                            type="Search"
                            id="input-search"
                            placeholder="User"
                            // maxLength={10}
                            style={{ border: "2px solid #000 " }}
                            value={searchUser}
                            onChange={(e) => {
                              setSearchUser(e.target.value.replace(/%/g, ""));
                              OnChangeHandleFilter(
                                e,
                                BoxGroup,
                                searchPo,
                                searchStyle,
                                e.target.value
                              );
                            }}
                            autoComplete="off"
                          />
                        </Form.Group>
                      </Col>
                    )}

                    <Col
                      lg={1}
                      style={{
                        // border: "2px solid blue",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "1.5%",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          handleFilter(
                            e,
                            BoxGroup,
                            searchPo,
                            searchStyle,
                            searchUser
                          );
                        }}
                        className="btn"
                      >
                        Filter
                      </Button>
                    </Col>
                    <Col
                      lg={1}
                      style={{
                        // border: "2px solid red",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "1.5%",
                      }}
                    >
                      <Button
                        variant="danger"
                        onClick={Handlereset}
                        className="btn"
                      >
                        Reset
                      </Button>
                    </Col>
                    {/* <Col xs={12} md={1} /> */}
                  </Row>
                </Form>
              </div>
            </div>
          </div>

          <div
            className="table-responsive mx-4 tableFixHead table-container"
            style={{
              marginTop: "180px",
              marginBottom: "40px",
              // position: "absolute",
            }}
          >
            {isLoading && !initialFetchDone ? (
              <Loader />
            ) : (
              <TooltipProvider>
                <table className="table table-bordered table-hover table-sm mt-4 table-header-for-all">
                  <thead>
                    <tr
                      className="table-warning"
                      style={{
                        position: "sticky",
                        top: "0", // Fix the header at the top of the table
                        zIndex: "10", // Higher zIndex to ensure it's above other table rows
                        backgroundColor: "#f8f9fa", // Ensure the header has a background
                      }}
                    >
                      <th>S.No.</th>
                      <th>SequenceID</th>
                      <th>BoxGroup</th>
                      <th>PO#</th>
                      <th>Style</th>
                      <th>TOT_QTY</th>
                      <th>ExFactory</th>
                      <th>ShipLoading</th>
                      <th>NoOfBoxes</th>
                      <th>BoxRange</th>
                      <th> CreatedAt </th>
                      <th>UpdatedAt</th>
                      {!!userData.admin && <th>User</th>}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody style={{ postion: "absolute" }}>
                    {filteredEntries.length > 0 ? (
                      filteredEntries.map((entry, index) => {
                        // console.log("ENTRIE5555ssssS", entry);
                        return (
                          <tr className="table-active" key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <Link to={`/boxDetails/${entry.id}`}>
                                {entry.id}
                              </Link>
                              {/* navigate(`/boxDetails/${seqId}`); */}
                            </td>

                            <td>{entry.BoxGroup}</td>
                            <td>{entry.PO}</td>
                            <td>{entry.STY}</td>
                            <td>{entry.TOT_QTY * entry.noOfBoxes}</td>
                            <td>{entry.exFactoryCount}</td>
                            <td>{entry.loadingCount}</td>
                            <td>{entry.noOfBoxes}</td>
                            <td>{`${entry.min_sno} - ${entry.max_sno}`}</td>
                            <td>{convertTimeToCountry(entry.createdAt)}</td>
                            <td>{convertTimeToCountry(entry.updatedAt)}</td>
                            {!!userData.admin && <td>{entry.username}</td>}
                            <td className="table-cell">
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "100%" }}
                              >
                                <div
                                  className="btn-group"
                                  style={{ position: "relative" }}
                                >
                                  <button

                                    className="btn btn-info"
                                    style={{ width: "90px", cursor: "pointer" }}
                                    disabled={
                                      entry.loadingCount > 0 ||
                                      userData.admin == 1
                                    }
                                    onClick={() => HandleModification(entry.id)}
                                  // onclick={alert("")}
                                  >
                                    Modify
                                  </button>
                                  <button

                                    className="btn btn-info dropdown-toggle dropdown-toggle-split"
                                    disabled={
                                      entry.loadingCount > 0 ||
                                      userData.admin == 1
                                    }
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{ width: "30px" }}
                                  >
                                    <span className="visually-hidden">
                                      Toggle Dropdown
                                    </span>
                                  </button>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <div
                                        className="dropdown-item"
                                        onClick={() =>
                                          HandleModification(entry.id)
                                        }
                                      >
                                        Regular
                                      </div>
                                    </li>
                                    <li>
                                      <div
                                        className="dropdown-item"
                                        onClick={() =>
                                          HandleSpecificModification(entry.id)
                                        }
                                      >
                                        Specific
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <Tooltip>
                                  {/* <TooltipTrigger
                                    as="span"
                                    style={{
                                      border: "none",
                                      background: "#dcdcdc",
                                      // zIndex: "10",
                                      postion: "relative",
                                    }}
                                  > */}
                                    <button
                                      className="btn btn-danger mx-1 " // Add this class to anchor the tooltip
                                      onClick={() => HandleDelete(entry.id)}
                                      disabled={
                                        entry.exFactoryCount > 0 ||
                                        userData.admin == 1
                                      }
                                      style={{
                                        zIndex: "5", // Button behind the header
                                        position: "relative",
                                      }}
                                    >
                                      {entry.isLoadingButton ? (
                                        <Spinner size="sm" />
                                      ) : (
                                        "Delete"
                                      )}
                                    </button>
                                  {/* </TooltipTrigger> */}
                                  <TooltipContent
                                    side="left"
                                    sideOffset={15}
                                    style={{
                                      zIndex: "20",
                                      // position: "absolute",
                                    }}
                                  >
                                    {(entry.exFactoryCount > 0 ||
                                      userData.admin == 1) && (
                                        <div
                                          style={{
                                            position: "relative",
                                            display: "inline-block",
                                            zIndex: "20",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block", // Ensures the width is applied
                                              width: "200px", // Set custom width
                                              backgroundColor: "#dcdcdc", // Set custom background color
                                              padding: "5% 5%", // Optional padding for spacing
                                              borderRadius: ".5em", // Optional for rounded corners
                                              textAlign: "center",
                                              color: "black", // Center the text
                                              border: "2px solid black",
                                              zIndex: "20",
                                            }}
                                          >
                                            Can not delete entry once you
                                            generated the barcode. Now you have to
                                            delete all PO entries by the
                                            administrator.
                                          </span>

                                          <TooltipArrow
                                            style={{
                                              width: "10px",
                                              height: "10px",
                                              transform: "rotate(0deg)",
                                              position: "absolute",
                                              bottom: "-18px",
                                              left: "0px",
                                              top: "-2px",
                                              zIndex: "2",
                                            }}
                                          />
                                        </div>
                                      )}
                                  </TooltipContent>
                                </Tooltip>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="14" className="text-center">
                          <h6>No data found</h6>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </TooltipProvider>
            )}

            {/* {!isLoading && !hasMore && <div>No more data</div>} */}
            {isLoading && initialFetchDone && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {/* {!!ctx.pageCountBoxEntries && (
              <ReactPaginate //!!ctx.pageCountPoSummary &&
                disableInitialCallback={true}
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={ctx.pageCountBoxEntries}
                forcePage={ctx.currentPageBoxEntries}
                // initialPage={ctx.currentPage}
                previousLabel="< prev"
                renderOnZeroPageCount={null}
                marginPagesDisplayed={2}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
              />
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
