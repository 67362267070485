// SocketContext.js
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext();

export const useSocketContext = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const socketRef = useRef(null);
  const url = process.env.REACT_APP_API_LINK;

  useEffect(() => {
    socketRef.current = io(url, {
      auth: {
        token: localStorage.getItem('token'),
      },
      withCredentials: true,
    });

    socketRef.current.on('connect', () => {
      setIsConnected(true);
    });

    socketRef.current.on('disconnect', () => {
      setIsConnected(false);
    });

    socketRef.current.on('connect_error', (err) => {
      console.error('Connection Error:', err);
      setIsConnected(false);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [url]);

  const emit = (eventName, data) => {
    if (socketRef.current) {
      socketRef.current.emit(eventName, data);
    }
  };

  const on = (eventName, callback) => {
    if (socketRef.current) {
      socketRef.current.on(eventName, callback);

      return () => {
        if (socketRef.current) {
          socketRef.current.off(eventName, callback);
        }
      };
    }
  };

  const disconnect = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
    }
  };

  return (
    <SocketContext.Provider value={{ isConnected, emit, on, disconnect }}>
      {children}
    </SocketContext.Provider>
  );
};
