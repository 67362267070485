import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./Login.css";
//import context from "../context/context";
import context from "../../context/context";

import axios from "axios";
import { Spinner } from "react-bootstrap";

const Login = () => {
  const [fname, setFname] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [killAndLogin, setKillAndLogin] = useState(false);
  const [userStatus, setUserStatus] = useState(0); // it holds the user lock status have set the  value in catch of  api :   "/auth/login",

  const handleCheckboxChange = () => {
    setKillAndLogin(!killAndLogin);
  };

  const secretQuestion = () => {
    return (
      <form>
        <label>
          Input:
          <input type="text" />
        </label>

        <button type="submit">Submit</button>
      </form>
    );
  };
  const [isLoading, setIsLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  // Check if user already login
  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("redirect") === "loadPo") navigate("/LoadPos");
      else navigate("/poSummary");

      localStorage.removeItem("redirect");
    }
  }, []);

  const Login = (e) => {
    setIsLoading(true);

    e.preventDefault();
    axios
      .post(
        "/auth/login",
        {
          username: fname,
          password: password,
          killAndLogin,
        },
        {}
      )
      .then((response) => {
        // console.log("resss", response);
        // console.log("locked", response.data.user.user);
        setUserStatus(response.data.user.user);

        localStorage.setItem("token", response.data.token);

        localStorage.setItem(
          "redirect",
          !!response?.data?.user?.loadPO ? "loadPo" : "poSummary"
        );
        window.location.reload(false);
        setTimeout(() => {
          setIsLoading(false);
        }, 5000);
      })
      .catch((err) => {
        // console.log(err);
        // console.log("err", err);
        alert(err.response.data.message);
        setUserStatus(err.response.request.status);

        setIsLoading(false);
      });
  };

  return (
    <>
      <div
        style={{
          backgroundImage: "url(/Assets/mogWh1.webp)",
          width: "100vw",
          height: "100vh",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <h1
          id="header"
          style={{
            textAlign: "center",
            fontSize: 48,
            // backgroundColor: "white",
          }}
        >
          Welcome to MOG
        </h1>
        <div className="Auth-form-container1">
          <form className="Auth-form1">
            <div className="Auth-form-content1 container1">
              <h3 className="Auth-form-title1"> Login</h3>
              <div className="form-group1 mt-3">
                <label> Username</label>
                <input
                  required
                  type="text"
                  className="form-control mt-1"
                  placeholder=" Enter username"
                  value={fname}
                  onChange={(e) => {
                    setFname(e.target.value.trim());
                  }}
                  maxLength={20}
                />
              </div>
              <div className="form-group mt-3">
                <label>Password</label>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <input
                    required
                    type={showPassword ? "text" : "password"}
                    className="form-control mt-1"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    minLength={5}
                    maxLength={10}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      width: "8%",
                      border: "none",
                      borderRadius: "50%",
                    }}
                    className="text-center fw-bold mt-2"
                    onClick={togglePasswordVisibility}
                  >
                    {/* <i className="fa fa-eye" /> */}
                    {showPassword ? (
                      <i className="fa fa-eye-slash" />
                    ) : (
                      <i className="fa fa-eye" />
                    )}{" "}
                  </div>
                </div>
              </div>
              {userStatus === 423 && (
                <div className="form-group mt-3">
                  <label>
                    <input
                      type="checkbox"
                      required
                      value="true"
                      checked={killAndLogin}
                      onChange={handleCheckboxChange}
                      onClick={secretQuestion}
                    />
                    Close Previous Session and Login
                  </label>
                </div>
              )}
              <div className="d-flex justify-content-center mt-4 ">
                <button
                  type="submit"
                  className="btn btn-primary "
                  onClick={Login}
                >
                  {isLoading ? <Spinner size="sm" /> : "Submit"}
                </button>
              </div>

              {/* <p className="forgot-password text-right mt-4 gap-1 d-flex justify-content-center ">
                Forgot <span href="#">password?</span>
              </p> */}
            </div>
          </form>
        </div>
        <div
          // direction={"right"}
          className="text-center bg-danger text-white fs-4 w-100"
          style={{ zIndex: "999", position: "absolute", bottom: 38 }}
        >
          Disclaimer: All data are for our Learning practice.
        </div>
      </div>
    </>
  );
};

export default Login;
