import React from "react";
//import Header from "../../components/header";
import Table from "./Table";

const Entries = () => {
  return (
    <>
      {/* <Header /> */}
      <Table />
    </>
  );
};

export default Entries;
