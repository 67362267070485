import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import InputBox from "./InputBox";
// import Filterfields from "./Filterfields";
import Weights from "./Weights";
import Countdown from "react-countdown";
import Context from "../../context/context";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Headroom from "react-headroom";
import Header from "../../components/header";
import Footer from "../../components/Footer";
import ErrosToast from "../../components/ErrosToast";

const Table = () => {
  const ctx = useContext(Context);
  const polockTime = new Date(ctx?.poData[0]?.lockInTime).getTime();
  const navigate = useNavigate();
  const [timeout, setTimeout] = useState(polockTime);

  const [totalQtyFromInputBox, setTotalQtyFromInputBox] = useState([]);
  const [lotTotalFromInputBox, setLotTotalFromInputBox] = useState([]);

  const [marginTop, setMarginTop] = useState(310); // for margin css

  // const [timeout, setTimeout] = useState(parseInt(polockTime)).slice(0, 4);
  // console.log(" polockTime", polockTime.toString().slice(0,6));

  //   setTimeout(function() {
  //     alert("This alert will appear only once after the specified delay.");
  // }, 2000);

  //time is about to end, please exceed it or finish the entry
  // console.log('total qty from inputbox', totalQtyFromInputBox)
  // console.log('total qty from lot', lotTotalFromInputBox)

  useEffect(() => {
    const handleZoomChange = () => {
      // console.log('inner width', window.innerWidth)
      // console.log('outerwidth', window.outerWidth)
      const zoomLevel = window.innerWidth / window.outerWidth;
      // console.log('zoom level', zoomLevel)
      // Calculate the new marginTop value based on the zoom level
      let newMarginTop = 310;
      if (zoomLevel < 1) {
        newMarginTop = 350;
      }

      setMarginTop(newMarginTop);
    };

    // Add an event listener for zoom changes
    window.addEventListener("resize", handleZoomChange);

    // Initial call to set the initial marginTop value
    handleZoomChange();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleZoomChange);
    };
  }, []);

  const updateLotTotals = (index, lotTotal) => {
    setLotTotalFromInputBox((prevLotTotals) => {
      const newLotTotals = [...prevLotTotals];
      newLotTotals[index] = lotTotal;
      return newLotTotals;
    });
  };

  const updateTotalQtyFromInputBox = (index, totalQty) => {
    setTotalQtyFromInputBox((prevTotalQty) => {
      const newTotQty = [...prevTotalQty];
      newTotQty[index] = totalQty;
      return newTotQty;
      // ...prevTotalQty,
      // [index]: totalQty,
    });
  };

  // console.log("set Po data", ctx.poData);

  return (
    <>
      <div className="fixed-top">
        <div style={{ background: "#f5f8fa" }}>
          <Header />
          <div className="mt-2 p-1">
            {ctx.weightData && (
              <Weights
                totInputBoxs={totalQtyFromInputBox}
                lotRatios={lotTotalFromInputBox}
              />
            )}
          </div>
          <div className="d-flex justify-content-center mt-3 p-1">
            <div className="bg-dark text-white fw-bold text-center rounded p-3 ">
              <Countdown
                className="text-center"
                date={timeout}
                zeroPadTime={2}
                v
                renderer={({ formatted }) => {
                  if (Object.values(formatted).every((x) => x === "00")) {
                    ctx.setUserEntries({});
                    ctx.setPOEntries({});
                    ctx.setPoData([]);
                    ctx.setWeightData({ numOfBoxes: 1 });
                    ctx.setSelectedCheckboxes([]);
                    ctx.setSelectedPo("");
                    ctx.setFilterClicked(false);
                    // ctx.setId("")
                    localStorage.removeItem("PO");
                    navigate("/");
                  }
                  return (
                    <div style={{ width: "65px", height: "22px" }}>
                      <span class="text-center">
                        {formatted.hours}:{formatted.minutes}:
                        {formatted.seconds}
                      </span>
                    </div>
                  );
                }}
              />
              {/* {console.log("date", date)} */}
            </div>
            <div className="px-4">
              {" "}
              <button
                className="btn btn-success fw-bold"
                style={{ height: "53px" }}
                onClick={async () => {
                  try {
                    await axios.post(
                      process.env.REACT_APP_API_LINK + "/api/v1/po/unlockPO",
                      {},
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "token"
                          )}`,
                        },
                      }
                    );
                    ctx.setUserEntries({});
                    ctx.setPOEntries({});
                    ctx.setPoData([]);
                    ctx.setWeightData({ numOfBoxes: 1 });
                    ctx.setSelectedCheckboxes([]);
                    ctx.setSelectedPo("");
                    ctx.setFilterClicked(false);
                    // ctx.setId("")
                    localStorage.removeItem("PO");
                    navigate("/");
                  } catch (err) {
                    // console.log(err);
                    ErrosToast(err.response.data.message);
                  }
                }}
              >
                Release
              </button>
            </div>
            <div className="p-0 m-0">
              <button
                className="btn btn-success fw-bold p-1 m-0"
                style={{ width: "100px", height: "53px" }}
                onClick={async () => {
                  try {
                    await axios.post("/po/extendLockinTime", {});
                    toast.success("Time extended by 20 minutes");
                    setTimeout(timeout + 20 * 60 * 1000);
                  } catch (err) {
                    // console.log(err);
                    ErrosToast(err.response.data.message);
                  }
                }}
              >
                Extend Time
              </button>
            </div>
          </div>
          {/* <FilterFields/> */}
        </div>
      </div>

      <div
        className="mx-4 tableFixHead"
        style={{ marginTop: `${marginTop}px`, paddingBottom: "100px" }}
      >
        {ctx.isFilter ? (
          <table className="table table-bordered border-gray table-sm">
            <thead className="">
              <tr className="table-warning border-secondary">
                {ctx.isFilter ? <th>Info</th> : null}
                <th>Fact</th>
                <th>Sup</th>
                <th>PO#</th>
                <th>Sea</th>
                <th>Sty</th>
                <th>Lot</th>
                <th>Clr</th>
                <th>Dim</th>
                <th>Tot_Qty</th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[0] : "SZ01"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[1] : "SZ02"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[2] : "SZ03"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[3] : "SZ04"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[4] : "SZ05"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[5] : "SZ06"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[6] : "SZ07"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[7] : "SZ08"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[8] : "SZ09"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[9] : "SZ10"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[10] : "SZ11"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[11] : "SZ12"}
                </th>
              </tr>
            </thead>
            <tbody>
              {ctx.poData.map((po, index) => {
                return (
                  <InputBox
                    key={"poData" + index}
                    po={po}
                    index={index}
                    updateLotTotals={updateLotTotals}
                    updateTotalQtyFromInputBox={updateTotalQtyFromInputBox}
                  />
                );
              })}
            </tbody>
          </table>
        ) : null}
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Table;
